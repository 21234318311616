import { DemoSection } from "np-platform-client/component/demo";
import { VideoLink } from "../link";
import { Narrow } from "np-platform-client/component/basics";

export function VideoLinkDemo() {
    return (
        <Narrow>
            <DemoSection label="Link to Video">
                <VideoLink
                    videoTitle={"Sarahs Leben mit dem Schmerz"}
                    videoUrl={"https://zdf.talkb.org/dokumentation/37-grad/endometriose-schmerzen-100.html"}
                    thumbnailUrl={"https://www.zdf.de/assets/endometriose-108~1280x720?cb=1679912477448"}
                />
            </DemoSection>
        </Narrow>
    );
}