import { TabNavigationDemo } from "./demo/tabnavigationdemo"
import { VideoVotingOptionDemo } from "./demo/videovotingoptiondemo"
import { PercentageBarDemo } from "./demo/percentagebardemo"
import { CountdownDemo } from "./demo/countdowndemo"
import { CircularBarDemo } from "./demo/circularbardemo"
import { VideoPlayerStateContextProviderDemo } from "./demo/customvideocontroldemo"
import { PositionableVideoOverlayDemo } from "./demo/positionablevideooverlaydemo"
import { QuestionResultConnectedDemo, QuestionResultIndividualDemo } from "./demo/videovotingquestionresultdemo"
import { VideoVotingQuestionDialogDemo } from "./demo/videovotingquestiondialogdemo"
import { ProfileSettingsFullDemo } from "./demo/profilesettingsdemo"
import { VideoLinkDemo } from "./demo/videolinkdemo"
import { PrivateThankYouMessageDemo, PrivateCommentFeedbackFullDemo } from "./demo/privatecommentfeedbackdemo"
import { ConversationHelperFullDemo, ConversationHelperHistoryDemo, ConversationHelperReactionsDemo, ThoughtProvokingPromptAdminDemo, ThoughtProvokingPromptDemo, ThoughtProvokingPromptStorySet } from "./demo/conversationhelper/conversationhelperdemo"


export const ZDFDemoFeature = {
    key: "demo_zdf",
    name: "Demo - ZDF",
    config: {
        componentSections: [
            {
                label: "ZDF",
                key: "zdf",
                pages: [
                    {
                        label: "Custom Video Control",
                        key: "customvideocontrol",
                        screen: VideoPlayerStateContextProviderDemo,
                        designUrl: null,
                    },
                    {
                        label: "Positionable Video Overlay",
                        key: "positionablevideooverlay",
                        screen: PositionableVideoOverlayDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=63-14800&t=xWo4yvIu6txN2nCu-0",
                    },
                    {
                        label: "Circular Progress Bar",
                        key: "circularbar",
                        screen: CircularBarDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-13201&t=xWo4yvIu6txN2nCu-0",
                    },
                    {
                        label: "Countdown Hook",
                        key: "countdown",
                        screen: CountdownDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-13201&t=xWo4yvIu6txN2nCu-0",
                    },
                    {
                        label: "Percentage Bar",
                        key: "percentagebar",
                        screen: PercentageBarDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-13201&t=sfCmlnUlQ3yQYkyv-0",
                    },
                    {
                        label: "Video Voting Option",
                        key: "videovotingoption",
                        screen: VideoVotingOptionDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-13201&t=sfCmlnUlQ3yQYkyv-0",
                    },
                    {
                        label: "Video Voting Question Dialog",
                        key: "videovotingquestiondialog",
                        screen: VideoVotingQuestionDialogDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-13201&t=T5gkaOoaJHS80PQM-0",
                    },
                    {
                        label: "Voting Result - Individual Question",
                        key: "videovotingquestionresultindividual",
                        screen: QuestionResultIndividualDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=63-14962&t=mr2JGbayv3pyFz0T-0",
                    },
                    {
                        label: "Voting Result - Connected Question",
                        key: "videovotingquestionresultconnected",
                        screen: QuestionResultConnectedDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=20-13201&t=Hbdjgdaa66pDfl0e-0",
                    },
                    {
                        label: "Tab Navigation",
                        key: "tabnavigation",
                        screen: TabNavigationDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=63-14854&t=ZLRDLJDAgLQ3gDiB-0",
                    },
                    {
                        label: "Video Link",
                        key: "videolink",
                        screen: VideoLinkDemo,
                        designUrl:
                            "https://www.figma.com/design/2LvWUQydUpat6ohKwYM12u/Video-Voting-Tool---Testing?node-id=63-14854&t=mr2JGbayv3pyFz0T-0",
                    },
                    {
                        label: "Thought-Provoking Prompt",
                        key: "thoughtprovokingprompt",
                        screen: ThoughtProvokingPromptDemo,
                        designUrl:
                            "https://www.figma.com/design/VDCebWSu7lU0GynG1qmtF5/Conversation-Helper---Testing?node-id=368-19878&t=AJVqkONSwh2ew3R4-0",
                    },
                    {
                        label: "Thought-Provoking Prompt Admin",
                        key: "thoughtprovokingpromptadmin",
                        screen: ThoughtProvokingPromptAdminDemo,
                    },
                    {
                        label: "Private Thank-You Message",
                        key: "thankyoumessage",
                        screen: PrivateThankYouMessageDemo,
                        designUrl:
                            "https://www.figma.com/design/VDCebWSu7lU0GynG1qmtF5/Conversation-Helper---Testing?node-id=368-19878&t=AJVqkONSwh2ew3R4-0",
                    },
                ],
            },
        ],
        structureSections: [
            {
                label: "ZDF",
                key: "zdf",
                pages: [
                    { label: "Conversation Helper", key: "conversationhelper", screen: ConversationHelperFullDemo },
                    {
                        label: "Private Comment Feedback",
                        key: "privatecommentfeedback",
                        screen: PrivateCommentFeedbackFullDemo,
                    },
                    {
                        label: "Profile Settings",
                        key: "profilesettingsfull",
                        screen: ProfileSettingsFullDemo,
                    },
                ],
            },
        ]
    },
};