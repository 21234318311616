import { Pad, spacings } from "np-platform-client/component/basics";
import { TextField } from "np-platform-client/component/text";
import { useDatastore, useGlobalProperty } from "np-platform-client/util/datastore";
import { useState } from "react";
import { View } from "react-native";
import { EditorialHeading } from "np-platform-client/component/text";
import { CTAButton } from "np-platform-client/component/button";

export const ProfileTitledWriterFeature = {
    name: 'Profile titled writer setting',
    key: 'profiletitledwritersetting',
    parentFeature: 'profilesettings',
    config: {
        settingWidgets: [ProfileTitledWriterSetting]
    }
}
function ProfileTitledWriterSetting() {
    return <View>
        <Editor />
    </View>
}

export function Editor() { 

    const datastore = useDatastore(); 
    const [badgeUrl, setBadgeUrl] = useState(useGlobalProperty('badgeUrl'))
    const [role, setRole] = useState(useGlobalProperty('role'))
    const [organization, setOrganization] = useState(useGlobalProperty('organization'))

    const onSave = () => {
        datastore.setGlobalProperty('role', role);
        datastore.setGlobalProperty('badgeUrl', badgeUrl);
        datastore.setGlobalProperty('organization', organization);
    }
    return <View>

        <Pad size={spacings.md} />
        <EditorialHeading level={5} strong text='badge pic url' />
        <Pad size={spacings.md} />
        <TextField value={badgeUrl}  onChange={(value) => setBadgeUrl(value)}  placeholder='url...' />

        <Pad size={spacings.md} />
        <EditorialHeading level={5} strong text='role' />
        <Pad size={spacings.md} />
        <TextField value={role} onChange={(value) => setRole(value)} placeholder='role... (ex: journalist)' />
        
        <Pad size={spacings.md} />
        <EditorialHeading level={5} strong text='role' />
        <Pad size={spacings.md} />
        <TextField value={organization}  onChange={(value) => setOrganization(value)}  placeholder='organization... (ex: CBC / RC)'/>
        
        <Pad size={spacings.md} />
        <CTAButton label='update user' onPress={onSave} />
    </View>
}



