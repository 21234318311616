import { HorizBox } from "np-platform-client/component/basics";
import { Toggle } from "np-platform-client/component/form";
import { UtilityText } from "np-platform-client/component/text";
import { useDatastore, useModulePublicData, usePersonaKey } from "np-platform-client/util/datastore";
import { logEventAsync } from "np-platform-client/util/eventlog";

export const ProfileVideoVotingSettingFeature = {
    name: 'Video Voting Setting',
    key: 'profilevideovotingsetting',
    parentFeature: 'profilesettings',
    config: {
        settingWidgets: [VideoVotingSetting]
    }
}

// TODO I am not sure if the setting makes sense as a feature. If you dont enable it by default, you would have to enable it for each user manually
export function VideoVotingSetting() {
    const personaKey = usePersonaKey();
    const datastore = useDatastore();

    const votingDisabledGloballyFromDatabase = useModulePublicData("videovoting", ["disabledForUser", personaKey]);

    const toggleVideoVoting = async () => {
        const newVotingDisabledGlobally = !votingDisabledGloballyFromDatabase;
        
        logEventAsync(datastore, 'videovoting-change-global-setting', {
            votingDisabledGlobally: newVotingDisabledGlobally.toString()
        });

        await datastore.callServerAsync('videovoting', 'toggleVideoVotingGlobally', {
            personaKey: personaKey,
            isDisabled: newVotingDisabledGlobally
        });
    }

    return <HorizBox center spread>
        <UtilityText strong label={"Enable voting for all videos"}></UtilityText>
        <Toggle value={!votingDisabledGloballyFromDatabase} onChange={toggleVideoVoting}/>
    </HorizBox>
}