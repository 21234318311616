import React from 'react';
import { useIsAdmin } from "np-platform-client/component/admin";
import { PadBox } from "np-platform-client/component/basics";
import { TextButton } from "np-platform-client/component/button";
import { UtilityText } from "np-platform-client/component/text"
import { useConfig } from "np-platform-client/util/features"
import { pushSubscreen } from "np-platform-client/util/navigate";
import { useServersideConstructor } from 'np-platform-client/component/constructor';
import { useDatastore } from 'np-platform-client/util/datastore';

export const ArticleStructure = {
    key: 'article',
    name: 'Article',
    screen: ArticleScreen,
    teaser: ArticleTeaser,
    subscreens: {
        admin: () => <ArticleAdminScreen />
    },
    defaultConfig: {
        teaser: null,
        adminScreen: null
    }
}

function ArticleScreen() {
    return <UtilityText label='No main screen defined.' />
}

function ArticleTeaser(params) {
    useServersideConstructor();
    const datastore = useDatastore();
    const {teaser} = useConfig();
    const isAdmin = useIsAdmin();
    if (teaser) {
        return React.createElement(teaser, params);
    } else if (isAdmin) {
        return <TextButton label='Open admin screen' onPress={() => datastore.pushSubscreen('admin')} />
    } else {
        return <UtilityText label='No teaser defined' />
    }
}

function ArticleAdminScreen() {
    const {adminScreen} = useConfig();
    if (adminScreen) {
        return React.createElement(adminScreen);
    } else {
        return <PadBox horiz={20} vert={20}><UtilityText label='No admin screen defined. Use the feature selector to enable a feature.' /></PadBox>
    }
}

