import { HorizBox, Pad, PadBox } from "np-platform-client/component/basics";
import { colorGreyPopupBackground, colorTextGrey } from "np-platform-client/component/color";
import { LinkText, UtilityText } from "np-platform-client/component/text";
import { Image, StyleSheet, View } from "react-native";

export function VideoLink({ videoTitle, videoUrl, thumbnailUrl }) {
    const s = VideoLinkStyle;
    return <View style={s.linkContainer}>
        <PadBox horiz={10} vert={10}>
            <HorizBox center>
                <Image style={s.previewImage} source={{ uri: thumbnailUrl }} />
                <Pad size={10}/>
                <UtilityText label={videoTitle} color={colorTextGrey} />
                <Pad size={10}/>
                <LinkText label="Go to video" url={videoUrl} />
            </HorizBox>
        </PadBox>
    </View>
}

const VideoLinkStyle = StyleSheet.create({
    linkContainer: {
        backgroundColor: colorGreyPopupBackground,
        borderRadius: "8px"
    },
    previewImage: {
        width: "50px",
        height: "50px",
        borderRadius: "8px"
    }
});