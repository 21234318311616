import { useState } from 'react';
import { View } from "react-native";

import { Pad, PadBox, ConversationScreen } from "np-platform-client/component/basics";
import { CTAButton } from "np-platform-client/component/button";
import { Toggle, FormField, AccordionField } from 'np-platform-client/component/form';
import { UtilityText, TextField, Heading } from "np-platform-client/component/text";
import { useDatastore, useModulePublicData } from 'np-platform-client/util/datastore';

import { STATIC_TOPIC } from "../../../structure/cbcrc/topic/static";



export const TopicFeature = {
    name: 'Topic Creation Feature',
    key: 'editorialtopic',
    config: {
        adminScreen: AdminScreen,
    }
}

function AdminScreen() {
    return <ConversationScreen>
        <Pad />
        <PadBox horiz={20}>
            <Heading label='Create a topic' />
        </PadBox>
        <PadBox horiz={20} vert={20}>
            <CreateTopicForm />
        </PadBox>
    </ConversationScreen>
}

export function CreateTopicForm() {
    const datastore = useDatastore();

    const [saveInProgress, setSaveInProgress] = useState(false);

    const [topicName, setTopicName] = useState('');
    const [startAt, setStartAt] = useState('');
    const [endAt, setEndAt] = useState('');
    const [conversationStarter, setConversationStarter] = useState('');
    const [active, setActive] = useState(false);
    
    const [pollObject, setPollObject] = useState({
        question: '',
    });
    const [nextTopicPollObject, setNextTopicPollObject] = useState({
        question: '',
        otherOptionValue: 'Autre',
        otherOptionPlaceholder: 'Rédiger un sujet...',
    });

    const [optionsValues, setOptionsValues] = useState(['']);
    const [upcomingPollOptions, setUpcomingPollOptions] = useState(['']);
    const onAddPollOption = () => {
        const newOptions = [...optionsValues, ''];
        setOptionsValues(newOptions);
    };
    const onRemovePollOption = () => {
        if (optionsValues.length > 0) {
            const newOptions = optionsValues.slice(0, optionsValues.length - 1);
            setOptionsValues(newOptions);
        }
    };

    const onAddUpcomingPollOption = () => {
        const newOptions = [...upcomingPollOptions, ''];
        setUpcomingPollOptions(newOptions);
    };
    const onRemoveUpcomingPollOption = () => {
        if (optionsValues.length > 0) {
            const newOptions = upcomingPollOptions.slice(0, upcomingPollOptions.length - 1);
            setUpcomingPollOptions(newOptions);
        }
    };

    const updatePollOption = ({ index, value }) => {
        const newValues = [...optionsValues];
        newValues[index] = value;
        setOptionsValues(newValues);
    }

    const updateUpcomingPollOption = ({ index, value }) => {
        const newValues = [...upcomingPollOptions];
        newValues[index] = value;
        setUpcomingPollOptions(newValues);
    }

    const topic = STATIC_TOPIC;

    async function onAdd() {
        setSaveInProgress(true);
        const result = await datastore.callServerAsync('topics', 'add', {
            topic: {
                ...topic,
                name: topicName,
                startAt: startAt,
                endAt: endAt,
                conversationStarter: conversationStarter,
                poll: {
                    ...pollObject,
                    type: 'MultipleChoice',
                    showDataviz: true,
                    options: optionsValues.map(x => ({ label: x })),
                },
                nextTopicPoll: {
                    ...nextTopicPollObject,
                    type: 'MultipleChoiceOther',
                    showDataviz: false,
                    options: upcomingPollOptions.map(x => ({ label: x })),
                },
                relatedConversations: [],
            }
        });
        setSaveInProgress(false);
        setTopicName('');
        setStartAt('');
        setEndAt('');
        setConversationStarter('');
        setActive(false);
        setOptionsValues(['']);
        setUpcomingPollOptions(['']);
        setPollObject({
            question: '',
        });
        setNextTopicPollObject({
            question: '',
            otherOptionValue: 'Autre',
            otherOptionPlaceholder: 'Rédiger un sujet...',
        });
    }

    return <SpacedArray>
        <FormField label='Topic name'>
            <TextField autoFocus value={topicName} onChange={setTopicName}
                label='Ask a question' placeholder='Topic name' />
        </FormField>
        <FormField label='Starts at'>
            <TextField label='startAt' value={startAt} onChange={setStartAt} placeholder='(YYYY-MM-DD format)' />
        </FormField>
        <FormField label='Ends at'>
            <TextField
                label='endsAt'
                placeholder='(YYYY-MM-DD format)'
                value={endAt}
                onChange={setEndAt}
            />
        </FormField>
        <FormField label='Conversation starter'>
            <TextField
                label='conversationStarter'
                placeholder='Conversation starter'
                value={conversationStarter}
                onChange={setConversationStarter}
            />
        </FormField>
        <FormField label="Is active">
            <Toggle label='Active' value={active} onChange={setActive} />
        </FormField>
        <PollFields
            pollObject={pollObject}
            setPollObject={setPollObject}
            sectionLabel={'Topic poll'}
            values={optionsValues}
            onAddValue={onAddPollOption}
            onRemoveValue={onRemovePollOption}
            onUpdateValue={updatePollOption}
        />
        <PollFields
            pollObject={nextTopicPollObject}
            setPollObject={setNextTopicPollObject}
            sectionLabel={'Upcoming topic poll'}
            values={upcomingPollOptions}
            onAddValue={onAddUpcomingPollOption}
            onRemoveValue={onRemoveUpcomingPollOption}
            onUpdateValue={updateUpcomingPollOption}
            hasComment
        />
        <Pad />
        {saveInProgress ?
            <CTAButton disabled label='Adding...' />
            :
            <CTAButton label='Add topic' onPress={onAdd} />
        }
    </SpacedArray>
}

export function PollFields({ pollObject, setPollObject, sectionLabel, values, onAddValue, onRemoveValue, onUpdateValue, hasComment }) {
    return <AccordionField titleContent={<UtilityText strong label={sectionLabel} />}>
        <SpacedArray>
            <FormField label='Poll question'>
                <TextField value={pollObject.question} onChange={(value) => setPollObject({...pollObject, question: value})} placeholder='Text Field' />
            </FormField>
            {hasComment &&
                <FormField label='Open answer label'>
                    <TextField value={pollObject.otherOptionValue}  onChange={(value) => setPollObject({...pollObject, otherOptionValue: value})} placeholder='Text Field' />
                </FormField>}
            {hasComment &&
                <FormField label='Open answer placeholder'>
                    <TextField value={pollObject.otherOptionPlaceholder}  onChange={(value) => setPollObject({...pollObject, otherOptionPlaceholder: value})} placeholder='Text Field' />
                </FormField>}
            <UtilityText type='small' strong label={'Poll options'} />
            <SpacedArray horiz>
                <CTAButton type='secondary' label='Remove option' onPress={onRemoveValue} />
                <CTAButton type='primary' label='Add option' onPress={onAddValue} />
            </SpacedArray>
            {values.length > 0 &&
                <>
                    {values.map((x, index) => (
                        <PadBox key={index} vert={12}>
                            <TextField
                                key={index}
                                placeholder='Option...'
                                value={x}
                                onChange={(value) => onUpdateValue({ index, value })}
                            />
                        </PadBox>
                    ))
                    }
                </>
            }
        </SpacedArray>
    </AccordionField>
}


function SpacedArray({ pad = 16, horiz = false, children }) {
    if (children.length > 1) {
        return <View style={horiz ? { flexDirection: 'row' } : null}>
            {children.map((c, i) => <View key={i} style={horiz ? { flexDirection: 'row' } : null}>
                {c}
                {i < children.length - 1 ? <Pad size={pad} /> : null}
            </View>)}
        </View>
    } else {
        return children;
    }
}


export function useGlobalTopics() {
    const topicObjs = useModulePublicData('topic');
    const keys = Object.keys(topicObjs || {});
    const topics = keys.map(key => ({ key, ...topicObjs[key] }));
    return topics;
}