import { ScrollView, Text, View } from "react-native";
import { CTAButton, IconButton, Tag, TextButton } from "np-platform-client/component/button";
import { Card, HorizBox, Pad, PadBox, Separator } from "np-platform-client/component/basics";
import { Heading, TextField, UtilityText } from "np-platform-client/component/text";
import { useCollection, useDatastore, useGlobalProperty, usePersonaKey } from "np-platform-client/util/datastore";
import { Banner } from "np-platform-client/component/banner";
import { Comment } from "np-platform-client/component/comment";
import { ArticleCard } from "../../structure/question";

export const ProfileConversationRecommendationsFeature = {
    name: 'Profile Conversation Recommendations',
    key: 'profileconversationrecommendations',
    config: {
        profileWidgets: [ProfileConversationRecommendationWidget]
    }
}

function ProfileConversationRecommendationWidget() {
    return <ProfileConversationRecommendationBox></ProfileConversationRecommendationBox>
}

function ProfileConversationRecommendationBox(){
    const myRecommendations = useCollection("commentrecommendation")
    
    const datastore = useDatastore();
    const myInterests = useGlobalProperty("interests")
    if(!myRecommendations || myRecommendations.length == 0){
        return <></>
    }
    return <View>
        <Separator></Separator>
        <Pad></Pad>
        <Heading label={"These conversations might interest you:"}></Heading>

        {myRecommendations.map(comment =>(
            <PadBox vert={8} horiz={8} key={"recommended-comment-" + comment.commentKey}>
                <Banner color="lightgray">
                    <ArticleCard article={comment.article} tags={comment.article.tags} thumbsize={64}></ArticleCard>
                    <Pad vert={4}></Pad>
                    <Comment commentKey={comment.commentKey}></Comment>
                    <MatchingTags tags={comment.tags}></MatchingTags>
                    <Pad vert={4}></Pad>
                    <HorizBox spread>
                        <IconButton label={"Jump to discussion"}></IconButton>
                        <Pad vert={4}></Pad>
                        <IconButton label={"Dismiss"} onPress={()=>datastore.deleteObject("commentrecommendation", comment.commentKey)}></IconButton>
                    </HorizBox>
                </Banner>  
            </PadBox>
        ))}
    </View>
}

function MatchingTags({tags}){
    const myInterests = useGlobalProperty("interests")
    if(!tags || !myInterests)return <></>
    const matchingInterests = myInterests.filter(interest => tags.includes(interest))

    if(matchingInterests.length > 0){
        return <HorizBox center>
            <UtilityText label={"This conversation matches your interest in: "}></UtilityText>
            <Tag color={"white"} label={matchingInterests[0]}></Tag>
        </HorizBox>
    } else {
        return <></>
    }
}