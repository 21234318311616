import { ConversationScreen, Narrow } from "np-platform-client/component/basics";
import { DemoSection } from "np-platform-client/component/demo";
import { SliderWithStopPoints } from "../component/sliderwithstoppoints";
import { PollPicker } from "../component/pollpicker";
import { BarChartVisualization, HorizBarVisualization, Selector } from "../feature/question/CommentSliderFeature";
import { PollResult } from "../feature/cbcrc/poll/poll";
import { ColoredBallPill } from "../component/colorpill";
import { useState } from "react";
import { Datastore } from "np-platform-client/util/datastore";
import { RatingSelector } from "../component/ratingselector";
import { StructureDemo } from "np-platform-client/util/instance";

const config = {
     commentSliderLabels: ['Strongly no', 'No with reservations',
        "It's complicated", "Yes with reservations",
        "Strongly yes"],
    commentAllowEmpty: true,
    commentSliderVisualization: BarChartVisualization
}

export function CommentSliderComponentsDemo() {
    const [value, setValue] = useState(null)
    const [comment, setComment] = useState({text: '', slider: null});
    const optionLabels = ['Strongly no', 'No with reservations',
        "It's complicated", "Yes with reservations",
        "Strongly yes"]
    const optionColors = ['red', 'orange', 'yellow', 'green', 'blue'];
    const ratingCounts = {
        1: 5,
        2: 3,
        3: 7,
        4: 2,
        5: 8
    }
    const commentsWithSlider = [
        {key: 1, from: 'a', text: 'I love this movie!', slider: 5},
        {key: 2, from: 'b', text: 'It sucks', slider: 1},
        {key: 3, from: 'c', text: 'The cinematics are awesome, but no plot', slider: 3},
        {key: 4, from: 'd', text: 'How did this get made', slider: 1},
    ]
 
    return <ConversationScreen>
        <Narrow>
            <DemoSection label='Slider With Stops'>
                <SliderWithStopPoints title='No Default' 
                    numberOfStops={5}
                    value={value}
                    onChangeValue={setValue}
                    optionColors={optionColors}
                    optionLabels={optionLabels} />
                <SliderWithStopPoints title='Translucent Default' 
                    numberOfStops={5}
                    value={value ?? 3}
                    isDefault={!value}
                    onChangeValue={setValue}
                    optionColors={optionColors}
                    optionLabels={optionLabels} />
                <SliderWithStopPoints title='Default to Center' 
                    numberOfStops={5}
                    value={value ?? 3}
                    onChangeValue={setValue}
                    optionColors={optionColors}
                    optionLabels={optionLabels} />
            </DemoSection>
            <DemoSection label='Poll Picker'>
                <PollPicker value={value} options={optionLabels} 
                    optionColors={optionColors} placeholder='Are cats cute?' 
                    onChangeValue={setValue} />
            </DemoSection>
            <DemoSection label='Selector'>
                <Datastore config={{
                        commentSliderLabels: ['Strongly no', 'No with reservations', "It's complicated", "Yes with reservations", "Strongly yes"],
                        commentSliderVisualization: BarChartVisualization,
                        commentSliderSelector: RatingSelector}} >
                    <Selector comment={comment} setComment={setComment} />
                </Datastore>
            </DemoSection>
            <DemoSection label='Bar Chart Visualization'>
                <Datastore config={config}>
                    <BarChartVisualization ratingCounts={ratingCounts} maxCount={8} responseCount={20} voteCount={5} />
                </Datastore>
            </DemoSection>
            <DemoSection label='Horiz Bar Visualization'>
                <Datastore config={config}>
                    <HorizBarVisualization commentsWithSlider={commentsWithSlider} />
                </Datastore>
            </DemoSection>
            <DemoSection label='Poll Result'>
                <PollResult options={optionLabels} myVote='Strongly No' 
                    votes={["It's complicated", 'Strongly yes', 'Strongly yes', 'No with reservations']} />
            </DemoSection>
            <DemoSection label='Colored Ball Pill'>
                <ColoredBallPill label='Yes' color='green' />
                <ColoredBallPill label='No' color='red' />
                <ColoredBallPill label='Maybe' color='yellow' />
            </DemoSection>
        </Narrow>
    </ConversationScreen>
}

const comments = [
    {key: 1, from: 'a', slider: 1, text: 'Godzilla sucks'},
    {key: 2, from: 'b', slider: 5, text: 'Godilla rules'},
    {key: 3, from: 'c', slider: 3, text: 'Godzilla does not exist'},
]

const globals = {
    name: 'Is Godzilla Good?',
    questionAuthor: 'a',
    questionTime: Date.now(),
}

export function CommentSliderFullDemo() {
    const collections = {comment: comments}
    return <StructureDemo globals={globals} collections={collections} structureKey='question' features={{commentslider: true}} />
}