export const firebaseConfig = {
    apiKey: "AIzaSyDIg3OR3i51VYrUyUd_L5iIownjdSnExlc",
    authDomain: "np-psi-dev.firebaseapp.com",
    databaseURL: "https://np-psi-dev-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "np-psi-dev",
    storageBucket: "np-psi-dev.appspot.com",
    messagingSenderId: "768032889623",
    appId: "1:768032889623:web:634a1604eda03820ab7552"
};


