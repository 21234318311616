import { Pad } from "np-platform-client/component/basics";
import { View } from "react-native";

export function TeaserScreen({children, color}) {
    function onLayout(e) {
        const {height} = e.nativeEvent.layout;
        window.parent.postMessage({type: 'psi-teaser-height', height}, '*');
    }
    const hasEmulateWarning = window.location.hostname == 'localhost';
    return <View onLayout={onLayout} style={{backgroundColor: color, borderRadius: 8, maxWidth: 797, width: '100%'}}>
        {children}
        {hasEmulateWarning && <Pad size={20} />}
    </View>
}

