import { Center, HorizBox, HoverView, Pad, PadBox } from "np-platform-client/component/basics";
import { colorBlack, colorDisabledBackground, colorGreyBorder, colorGreyPopupBackground, colorWhite } from "np-platform-client/component/color";
import { DataVizText, UtilityText } from "np-platform-client/component/text";
import { StyleSheet, Text } from "react-native";
import { View } from "react-native";
import { SliderWithStopPoints } from "./sliderwithstoppoints";
import { useEffect, useState } from "react";

// The rating selecton from Comment Slider. 
// Figma: https://www.figma.com/design/SgBNOg9PPIKIsSMAmU32rK/Comments-Slider---Testing?node-id=648-44526&m=dev

export function RatingSelector({value, defaultValue, options, optionColors, placeholder, onChangeValue}) {
    const s = RatingSelectorStyle;
    const sideOne = options[0];
    const sideTwo = options[4];
    const sideMiddle = options[2];    
    const label = value ? options[value - 1] : placeholder;

    return <View style={s.outer}>
        <PadBox horiz={20}><SliderWithStopPoints title={placeholder} numberOfStops={5} 
                value={value || defaultValue} onChangeValue={onChangeValue} 
                optionLabels={options} optionColors={optionColors}
            />
        </PadBox>

        <Pad size={12} />
        {value ? 
            <Center>
                <SelectionLabel ratingColors={optionColors} chosenRating={value} label={label} />
            </Center>
        :
            // TODO: Possible alternative way of placing labels - but looks bad on mobile
            // <View>
            //     <View style={s.leftLabel}><SelectionLabel ratingColors={optionColors} chosenRating={1} label={sideOne} /></View>
            //     <View style={s.centerLabel}><SelectionLabel ratingColors={optionColors} chosenRating={3} label={sideMiddle} /></View>
            //     <View style={s.rightLabel}><SelectionLabel ratingColors={optionColors} chosenRating={5} label={sideTwo} /></View>
            // </View>
            <HorizBox spread>
                <PadBox left={20}><SelectionLabel ratingColors={optionColors} chosenRating={1} label={sideOne} /></PadBox>
                <PadBox horiz={4}><SelectionLabel ratingColors={optionColors} chosenRating={3} label={sideMiddle} /></PadBox>
                <PadBox right={20}><SelectionLabel ratingColors={optionColors} chosenRating={5} label={sideTwo} /></PadBox>
            </HorizBox>
        }
    </View>
}

const RatingSelectorStyle = StyleSheet.create({
    leftLabel: {
        position: 'absolute',
        left: 20,
        width: '25%',
        flexShrink: 1,
    },
    rightLabel: {
        position: 'absolute',
        right: 20,
        width: '25%',
        flexShrink: 1,
    },
    centerLabel: {
        position: 'absolute',
        left: 0,
        right: 0,
        alignItems: 'center'
    },
    outer: {
        backgroundColor: colorGreyPopupBackground,
        borderRadius: 8,
        paddingVertical: 20
    },
    slider: {
        paddingTop: 26,
        marginBottom: 0,
        paddingBottom: 20,
        paddingHorizontal: 22
    },
    bar: {
        position: 'absolute',
        backgroundColor: colorGreyBorder,
        height: 8,
        borderRadius: 4,
        zIndex: -1,
        left: 20,
        right: 20,
        top: 25
    },
    toggleBall: {
        position: 'absolute',
        backgroundColor: colorWhite,
        width: 32,
        height: 32,
        borderRadius: 16,
        borderWidth: 1,
        borderColor: colorGreyPopupBackground,
        boxShadow: '0px 2px 10px rgba(0, 0, 0, 0.30)',
        zIndex: 1,
        left: 20,
        top: 16
    },
    selectionStopPoint: {
        width: 6,
        height: 6,
        borderRadius: 4,
        backgroundColor: colorWhite,
        borderLeftColor: colorGreyBorder,
        borderLeftWidth: 2,
        borderColor: colorGreyPopupBackground,
    },
})

function ColorBall({ratingColors, chosenRating}) {
    return <View style={{backgroundColor: ratingColors[chosenRating-1], borderRadius: 100, width: 8, height: 8}} />
}

function SelectionLabel({ratingColors, chosenRating, label}) {
    return <HorizBox center> 
        <ColorBall ratingColors={ratingColors} chosenRating={chosenRating} /> 
        <Pad size={6}/>
        <UtilityText strong type='tiny' label={label} />
    </HorizBox>
}
