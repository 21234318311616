import { Banner } from "np-platform-client/component/banner"
import { Narrow } from "np-platform-client/component/basics"
import { DemoHeader } from "np-platform-client/component/demo"
import { Heading } from "np-platform-client/component/text"
import { useState } from "react"
import { VideoPlayerStateContextProvider } from "../videoPlayerStateContext"
import { View } from "react-native"
import { SimpleVideo } from "../videoPlayer"
import { OverlayScreen, RepositionOverlay } from "../overlay"
import { CTAButton } from "np-platform-client/component/button"


export function PositionableVideoOverlayDemo() {
    const [positionedOverlay, setPositionedOverlay] = useState(false);

    return (
        <Narrow>
            <DemoHeader strong label="Positionable Video Overlay Demo" />
            <Banner>
                <Heading label="Setting repositionIFrameInstead will cause the whole PSI-Iframe to act as the inner container" />
            </Banner>
            <VideoPlayerStateContextProvider>
                <View>
                    <SimpleVideo
                        src={"https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4"}
                        controls
                    ></SimpleVideo>
                    <OverlayScreen repositionIFrameInstead={false}>
                        <View style={{ backgroundColor: "white", borderColor: "red", borderWidth: 2 }}>
                            {positionedOverlay && (
                                <View>
                                    <RepositionOverlay
                                        positionData={{ left: 20, top: 20 }}
                                        dimensionData={{ width: "80%", height: "100" }}
                                    ></RepositionOverlay>
                                    <Heading label="Positioned overlay is shown" />
                                    <Heading label="Different position and dimension is set" />
                                </View>
                            )}
                            {!positionedOverlay && (
                                <View>
                                    <Heading label="Default Overlay content is shown" />
                                    <Heading label="Default position and dimension is used" />
                                </View>
                            )}
                            <CTAButton
                                label={positionedOverlay ? "Show default overlay!" : "Show a positioned overlay!"}
                                onPress={() => setPositionedOverlay(!positionedOverlay)}
                            ></CTAButton>
                        </View>
                    </OverlayScreen>
                </View>
            </VideoPlayerStateContextProvider>
        </Narrow>
    );
}