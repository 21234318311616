import { Banner, ClickableBanner } from "np-platform-client/component/banner";
import { Pad, PadBox } from "np-platform-client/component/basics";
import { CTAButton, Tag } from "np-platform-client/component/button";
import { colorBlueBackground, colorPink } from "np-platform-client/component/color";
import { RichText } from "np-platform-client/component/richtext";
import { Heading, TextField, UtilityText } from "np-platform-client/component/text";
import { useDatastore } from "np-platform-client/util/datastore";
import { useState } from "react";
import { Modal } from "np-platform-client/component/modal";
import { goBack } from "np-platform-client/util/navigate";
import { logEventAsync, useLogEvent } from "np-platform-client/util/eventlog";

export const PreModeratorFeature = {
    name: 'Pre-Moderation',
    key: 'premoderation',
    config: {
        commentPostCheckers: [checkPostAsync],
        commentPostBlockers: [isPostButtonDisabledAsync],
        commentEditBottomWidgets: [ModerationReport],
        commentFilters: [filterFunc],
        replyFilters: [filterFunc],
        commentTopWidgets: [InReviewTag],
        replyAboveWidgets: [InReviewTag],
    }
}


function getRuleDescription(rule) {
    switch (rule) {
        case 0:
        case 1:
        case 6:
            return '**Avoid uncivil or disrespectful language**. You can express any opinion, however you must do so in a respectful way.';
        case 2:
            return '**Avoid calls for harm or celebration of harm**. You can advocate for actions that would have negative consequences for some people, but harm should not be the primary goal.';
        case 3:
            return '**Do not imply that someone has bad intent**. Assume they have good intentions and critique their ideas and their actions instead.';
        case 4:
            return '**Avoid attacking the character of others**. Critique their ideas and their actions instead.';
        case 5:
            return '**Avoid snark, sarcasm, and mockery**';
        case 7:
            return '**Controversial claims must provide a source URL**';
        default:
            return '**Be sure to write your response in a way that those who disagree with will be willing to listen to**';
    }
}

async function checkPostAsync({datastore, comment}) {
    if (!comment.text?.trim()) {
        return {allow: true, commentProps: {violates: false, inReview: false}};
    }

    const moderationResult = await datastore.callServerAsync('moderation', 'moderateComment', {
        text: comment.text, commentKey: comment.key || null, inReview: comment.inReview
    });
    const firstRuleBroken = moderationResult.rulesBroken?.[0];

    if (moderationResult.violates) {
        logEventAsync(datastore, 'moderation-block', {text: comment.text});
    }

    return {allow: !moderationResult.violates, commentProps: {
        violates: moderationResult.violates ?? false, 
        inReview: false,
        rulesBroken: JSON.stringify(moderationResult.rulesBroken ?? []),
        brokenRuleDescription: moderationResult.violates ? getRuleDescription(firstRuleBroken) : null,
        violatingText: moderationResult.violates ? comment.text : null,
    }};
}

function isPostButtonDisabledAsync({datastore, comment}) {
    return comment.violates && comment.violatingText == comment.text;
}


export function ModerationReport({comment, onCancel}) {
    const [modalShown, setModalShown] = useState(false);
    if (!comment.violates) {
        return null;
    } else {
        return <PadBox bottom={20}> 
            <ClickableBanner color={colorPink} onPress={() => setModalShown(true)}>
                <RichText label={comment.brokenRuleDescription} />
            </ClickableBanner>
            {modalShown && <ModerationModal comment={comment} onCancel={onCancel} onClose={() => setModalShown(false)} />}
        </PadBox>
    }
}

// TODO: Bring back link to transparency dashboard, once we have cross-partner alignment
// and it works better

export function ModerationModal({comment, onClose, onCancel}) {
    const [inProgress, setInProgress] = useState(false);
    const [appealing, setAppealing] = useState(false);
    const [sent, setSent] = useState(false);
    const [appealText, setAppealText] = useState('');
    const datastore = useDatastore();
    const siloKey = datastore.getSiloKey();
    useLogEvent('moderation-modal', {text: comment.text});
    async function onReport() {
        setInProgress(true);
        await datastore.callServerAsync('moderation', 'sendToReview', {
            comment, commentKey: comment.key, appealText
        });
        setInProgress(false);
        setSent(true);
        logEventAsync(datastore, 'moderation-appeal', {text: appealText});
    }

    function onDone() {
        onClose();
        if (onCancel) {
            onCancel();
        } else {
            goBack();
        }
    }

    const dashboardUrl = 'https://np-psi-dev.web.app/' + siloKey + '/moderationdash/dash';

    if (sent) {
        return <Modal onClose={onClose} buttonRow={
            <CTAButton wide type='secondary' label='I understand' onPress={onDone}/>
        }>
            <PadBox horiz={20} vert={20}>
                <Heading level={1} label='Your response has been sent to a moderator for review'/>
                <Pad />
                <RichText label='You will be notified when the moderator makes a decision. In the meantime, you can look at the [transparency dashboard]({dashboardUrl}) to see where your post is in our moderation queue.' formatParams={{dashboardUrl}}/> 
            </PadBox>  
        </Modal>
    } else if (appealing) {
        return <Modal onClose={onClose} buttonRow={
            <CTAButton disabled={inProgress || !appealText} wide type='primary' label={inProgress ? 'Sending...' : 'Send to moderator'} onPress={onReport}/>
        }>
            <PadBox horiz={20} vert={20}>
                <Heading level={1} label='Send to moderator'/>
                <Pad />
                <UtilityText caps strong label='Message to moderator*' />
                <Pad size={12} />
                <TextField big value={appealText} onChange={setAppealText} placeholder='Please explain your response (required)'/>
            </PadBox>  
        </Modal>
    } else {
        return <Modal onClose={onClose} buttonRow={<CTAButton wide type='secondary' label='Send to moderator' onPress={() => setAppealing(true)}/>}>
            <PadBox horiz={20} vert={20}>
                <Heading level={1} label='✋ Take another look'/>
                <Pad size={16} />
                <RichText label='Your post may violate our [Community Guidelines](https://example.com).'/>
                <Pad size={16} />
                <RichText label='You are free to express any opinion, however you must do so in a respectful way that avoids personal attacks, assumes good intent, and backs up contentious claims with sources.'/>
                <Pad size={16} />
                <Banner color={colorBlueBackground}>
                    <RichText label='**Sometimes our AI triggers incorrectly**'/>
                    <RichText label='If that happened then first try adjusting your wording to be more obviously respectful. If you are still unable to get the AI to accept a respectfully expressed opinion then please send it to our human moderator.'/>
                </Banner>
                <Pad size={16} />
                {/* <RichText label='To provide transparency, we offer an anonymized [public database]({dashboardUrl}) of all moderation decisions.' formatParams={{dashboardUrl}}/> */}
            </PadBox>
        </Modal>
    }
}

function filterFunc({datastore, isAdmin, comment}) {
    if (
            (comment.inReview || comment.violates) && 
            !isAdmin &&
            !(comment.replyTo && comment.from == datastore.getPersonaKey())) {
        return false;
    } else {
        return true;
    }
}

function InReviewTag({comment}) {
    if (comment.inReview) {
        return <PadBox bottom={20}>
            <Tag color={colorBlueBackground} label='🔍 Under Review' />
        </PadBox>
    } else if (comment.violates) {
        return <PadBox bottom={20}>
            <Tag color={colorPink} label='🚫 Rejected by Human Moderator' />
        </PadBox>
    } else {
        return null;
    }
}
