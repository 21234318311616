import React from "react";
import { ConversationScreen, HorizBox, HoverView, Pad, PadBox, Separator } from "np-platform-client/component/basics";
import { CTAButton, TextButton } from "np-platform-client/component/button";
import { colorBlackHover, colorNearBlack, colorPinkBackground, colorPrimaryPress, colorRed, colorTextGrey, colorWhite, colorGreyBorder } from "np-platform-client/component/color";
import { Heading, UtilityText } from "np-platform-client/component/text";
import { useCollection, useDatastore, useInstanceKey, useObject, useStructureKey } from "np-platform-client/util/datastore";
import { gotoInstance, gotoInstanceScreen } from "np-platform-client/util/navigate";
import { StyleSheet, View, Pressable } from "react-native";
import { TeaserScreen } from "../../component/teaser";
import { useIsAdmin } from "np-platform-client/component/admin";
import { Byline, ProfilePhoto } from "np-platform-client/component/people";
import { AddQuestionBox, useGlobalQuestions } from "../../component/question";
import { useState } from "react";
import { Catcher } from "np-platform-client/component/catcher";
import { AddComment, Edit } from "@carbon/icons-react";
import { keyToUrl, toBool } from "../../util/util";
import { useLogEvent } from "np-platform-client/util/eventlog";
import { Toggle } from "np-platform-client/component/form";
import { RichText } from "np-platform-client/component/richtext";
import { useConfig } from "np-platform-client/util/features";


export const EditorialQuestionFeature = {
    name: 'Editorial Question',
    key: 'editorialquestion',
    config: {
        teaser: EditorialQuestionTeaser,
        adminScreen: AdminScreen
    },
    defaultConfig: {
        teaserSingleQuestion: SingleQuestionTeaser,
        teaserMultiQuestion: MultiQuestionTeaser,
        showZeroStateGraphic: false,
    }
}

export const StandardTeaserFeature = {
    name: 'Standard Teaser',
    key: 'standardteaser',
    config: {}
}

export const CommentPreviewTeaserFeature = {
    name: 'Comment Preview Teaser',
    key: 'commentpreviewteaser',
    config: {
        teaserSingleQuestion: AdaptiveLayoutQuestionTeaserWithComment,
    }
}

export const ZeroStateGraphicTeaserFeature = {
    name: 'Zero State Teaser',
    key: 'zerostateteaser',
    config: {
        teaserSingleQuestion: AdaptiveLayoutQuestionTeaserWithComment,
        showZeroStateGraphic: true,
    },
};


function AdminScreen() {
    const questions = useGlobalQuestions();
    const [search, setSearch] = useState('');
    const localSelectedQuestions = useCollection('question').filter(q => !q.deleted);
    const datastore = useDatastore();
 
    const filteredQuestions = questions?.filter(question => !search || question.question?.toLowerCase().includes(search.toLowerCase()));
    const selectedQuestions = filteredQuestions.filter(question => localSelectedQuestions.find(q => q.key == question.key));
    const otherQuestions = filteredQuestions.filter(question => !selectedQuestions.find(q => q.key == question.key));

    async function onAdded(questionKey) {
        await datastore.callServerAsync('questions', 'select', {questionKey});
    }

    return <ConversationScreen>
        <Pad />
        <PadBox horiz={20}><Heading label='Choose Questions for this Article' /></PadBox>        
        <PadBox horiz={20} vert={20}>
            <AddQuestionBox onChange={setSearch} onAdded={onAdded} />
        </PadBox>
        <Separator />
        <Pad />
        {selectedQuestions?.map(question =>
            <Catcher key={question.key}><Question question={question} /></Catcher>
        )}
        {otherQuestions?.map(question =>
            <Catcher key={question.key}><Question question={question} /></Catcher>
        )}
    </ConversationScreen>
}

function Question({question}) {
    const datastore = useDatastore();
    const selectedQuestion = useObject('question', question.key);
    const isChosen = selectedQuestion && !selectedQuestion.deleted;

    async function onChange(selected) {
        await datastore.callServerAsync('questions', selected ? 'select' : 'unselect', {
            questionKey: question.key
        });
    }

    return <PadBox horiz={20}>
        <Toggle spread text={question.text ?? question.question} value={isChosen} onChange={onChange} />
    </PadBox>
}

export function EditorialQuestionTeaser() {
    const isAdmin = useIsAdmin();
    const questions = useCollection('question').filter(q => !q.deleted);
    const instanceKey = useInstanceKey();
    const {teaserSingleQuestion, teaserMultiQuestion} = useConfig();
    const url = keyToUrl(instanceKey);
    useLogEvent('teaser-show', {url});

    if (questions.length == 1) {
        return <TeaserScreen>
            {React.createElement(teaserSingleQuestion, {question: questions[0]})}
        </TeaserScreen>
    } else if (questions.length > 1) {
        return <TeaserScreen>
            {React.createElement(teaserMultiQuestion, {questions})}
        </TeaserScreen>
    } else if (isAdmin) {
        return <TeaserScreen>
            <AdminTeaserAction />
        </TeaserScreen>
    } else {
        return <TeaserScreen>
           <UtilityText label='No question for this content' />
        </TeaserScreen>;
    }
}

function AdminTeaserAction() {
    const instanceKey = useInstanceKey();
    const structureKey = useStructureKey();
    return <CTAButton type='secondary' label='Edit questions (admin)' 
        onPress={() => gotoInstanceScreen({structureKey, instanceKey, screenKey: 'admin', params: {}})} />
}

function PinkBox({children}) {
    return <View style={{backgroundColor: colorPinkBackground, borderRadius: 10}}>
        {children}
    </View>
}

export function MultiQuestionTeaser({questions}) {
    const isAdmin = useIsAdmin();

    return <PinkBox>
        <PadBox horiz={20} vert={20}>
            <HorizBox center>
                <AddComment />
                <Pad size={8} />
                <UtilityText type='tiny' label='Related Conversations' caps strong />
            </HorizBox>
            <Pad size={16} />
            {questions.map((question, idx) => 
                <MiniQuestionPromo key={question.key} question={question} showSeparator={idx > 0} />
            )}
            {isAdmin && <PadBox top={20}><AdminTeaserAction /></PadBox>}
        </PadBox>
    </PinkBox>
}

export function SingleQuestionTeaser({question}) {
    const isAdmin = useIsAdmin();
    const questionPreview = useObject('derived_questionPreview', question.key);

    return <PinkBox>
        <PadBox horiz={20} vert={20}>
            <HorizBox center>
                <AddComment />
                <Pad size={8} />
                <UtilityText type='tiny' label='Related Conversation' caps strong />
                <Pad size={8} />
                {questionPreview?.commentCount > 0 ? 
                    <UtilityText type='tiny' color={colorBlackHover} label='{count} {noun}' formatParams={{count: questionPreview?.commentCount, singular: 'response', plural: 'responses'}} />
                :
                    <UtilityText type='tiny' color={colorBlackHover} label='Be the first to share your thoughts' />
                }
            </HorizBox>
            <Pad size={16} />
            <MiniQuestionPromo key={question.key} question={question} showCount={false} joinButton />
            {toBool(isAdmin) && <PadBox top={20}><AdminTeaserAction/></PadBox>}
        </PadBox>
    </PinkBox>
}


export function MiniQuestionPromo({question, showSeparator=false, joinButton=false, showCount=true}) {
    const s = MiniQuestionPromoStyle;
    const questionPreview = useObject('derived_questionPreview', question.key);
    return <View>
        {showSeparator && <PadBox top={12} bottom={12}><Separator /></PadBox>}
        <HorizBox center>  
            <PadBox top={4}><ProfilePhoto userId={question.from} type='large' /></PadBox>
            <Pad size={12} />
            <View style={{flex: 1}}>
                <TextButton level={5} editorial type='small' style={s.question} text={question.text}                 
                    onPress={() => gotoInstance({structureKey: 'question', instanceKey: question.key})} 
                /> 
                {showCount && <Pad size={4} />}
                {showCount && (questionPreview?.commentCount ? 
                    <UtilityText type='tiny' color={colorBlackHover} label='{count} {noun}' formatParams={{count: questionPreview?.commentCount, singular: 'response', plural: 'responses'}} />
                :
                    <UtilityText type='tiny' color={colorBlackHover} label='Be the first to share your thoughts' />
                )}
            </View>
            {joinButton && 
                <CTAButton label='Join' onPress={() => gotoInstance({structureKey: 'question', instanceKey: question.key})} />
            }           
        </HorizBox>
    </View>
}
const MiniQuestionPromoStyle = StyleSheet.create({
    question: {
        fontFamily: 'PlayfairDisplay_400Regular',
        fontSize: 18,
    },
})

export function AdaptiveLayoutQuestionTeaserWithComment({question}) {
    const [narrow, setNarrow] = useState(false);

    const questionPreview = useObject('derived_questionPreview', question.key);
    const commentPreview = questionPreview?.commentPreview;
    const commentCount = questionPreview?.commentCount;
    const isAdmin = useIsAdmin();

    function onLayout(event) {
        const {width} = event.nativeEvent.layout;
        setNarrow(width < 540);
    }

    return <TeaserScreen>
        <Pressable onLayout={onLayout} onPress={() => gotoInstance({structureKey: 'question', instanceKey: question.key})} >
            {narrow ? 
                <NarrowQuestionTeaserWithComment question={question} commentPreview={commentPreview} commentCount={commentCount} />
            :
                <WideQuestionTeaserWithCommnt question={question} commentPreview={commentPreview} commentCount={commentCount} />
            }
        </Pressable>
        {isAdmin && <PadBox top={20}><AdminTeaserAction /></PadBox>}
    </TeaserScreen>
}

export function NarrowQuestionTeaserWithComment({question, commentPreview, commentCount}) {
    const s = NarrowQuestionTeaserStyle;
    const {showZeroStateGraphic} = useConfig();
    return <View style={s.outer}>
        <PadBox horiz={20} vert={20}>
            <QuestionInfo question={question} commentCount={commentCount} narrow />
        </PadBox>
        <View style={s.comment}>
            {(commentPreview || showZeroStateGraphic) &&
                <PadBox bottom={20}><CommentPreview commentPreview={commentPreview}/></PadBox>
            }  
            <TeaserButton label='Share your thoughts' icon={<Edit color='white' />} wide={true}
                onPress={() => gotoInstance({structureKey: 'question', instanceKey: question.key})}
            />
        </View>
    </View>
}

const NarrowQuestionTeaserStyle = StyleSheet.create({
    outer: {
        backgroundColor: colorPinkBackground,
        borderRadius: 10,
    },
    comment: {
        backgroundColor: '#F6F0EC',
        padding: 20,
        flex: 1,
        justifyContent: 'center',
        borderBottomRightRadius: 10,
        borderBottomLeftRadius: 10,   
    }
})


export function WideQuestionTeaserWithCommnt({question, commentPreview, commentCount}) {
    const s = WideQuestionTeaserStyle;
    const {showZeroStateGraphic} = useConfig()
    return <View style={s.outer}>
        <View style={s.left}>
            <QuestionInfo question={question} commentCount={commentCount} />
            <Pad size={20} />
            <TeaserButton label='Share your thoughts' icon={<Edit color='white' />}
                onPress={() => gotoInstance({structureKey: 'question', instanceKey: question.key})}
            />
        </View>
        <View style={s.right}>
            {(commentPreview || showZeroStateGraphic) && 
                <CommentPreview commentPreview={commentPreview}/>
            }                
        </View>
    </View>
}

const WideQuestionTeaserStyle = StyleSheet.create({
    outer: {
        borderRadius: 10,
        flexDirection: 'row',
    },
    left: {
        backgroundColor: colorPinkBackground,
        padding: 24,
        borderTopLeftRadius: 10,
        borderBottomLeftRadius: 10,
        flex: 1,
    },
    right: {
        backgroundColor: '#F6F0EC',
        padding: 24,
        flex: 1,
        justifyContent: 'center',
        borderTopRightRadius: 10,
        borderBottomRightRadius: 10,    
    }
})


export function CommentPreview({commentPreview}) {
    const {showZeroStateGraphic} = useConfig()

    if (showZeroStateGraphic && !commentPreview) {
        return <SkeletonCommentInCardStack />
    }

    return <CardStack>
        <PadBox horiz={20} vert={16}>
            <Byline userId={commentPreview.from} photoType='tiny' clickable={false} time={commentPreview.time} />
            <Pad size={12} />
            <RichText numberOfLines={2} text={commentPreview.text} />
        </PadBox>
    </CardStack>
}

export function QuestionInfo({question, commentCount, narrow=false}) {
    return <View>
        <HorizBox center>
            <AddComment color={colorBlackHover} />
            <Pad size={8} />
            <UtilityText type='tiny' label='Join the Conversation' color={colorBlackHover} caps />
        </HorizBox>
        <Pad size={narrow ? 20 :24}/>
        <Heading text={question.text} level={1}></Heading>
        <Pad size={8}/>
        {commentCount ? 
            <UtilityText type='tiny' label='{count} {noun}' color={colorBlackHover}
                formatParams={{count: commentCount, singular: 'response', plural: 'responses'}} 
            />
        :
            <UtilityText type='tiny' label='Be the first to share your thoughts' color={colorBlackHover} />
        }
    </View>
}

export function TeaserButton({label, icon, onPress, wide}) {
    const s = TeaserButtonStyle;
    const buttonStyle = wide ? {...s.button, ...s.wideStyle} : s.button;
    return <HoverView onPress={onPress} style={buttonStyle} hoverStyle={s.hover} pressedStyle={s.pressed}>
        <UtilityText type='large' label={label} color={colorWhite}/>
        {icon && <PadBox left={12}>{icon}</PadBox>}
    </HoverView>
}
const TeaserButtonStyle = StyleSheet.create({
    button: {
        backgroundColor: colorNearBlack,
        borderColor: colorNearBlack,
        paddingHorizontal: 20,
        paddingVertical: 12,
        borderRadius: 32,
        alignSelf: 'flex-start',
        borderWidth: 1,
        flexDirection: 'row',
        height: 40, 
        alignItems: 'center',
    },
    wideStyle: {
        alignSelf: 'stretch', 
        justifyContent: 'center', 
        flex: 1
    },
    hover: {
        backgroundColor: colorBlackHover,
        borderColor: colorBlackHover,
    },
    pressed: {
        backgroundColor: colorPrimaryPress
    }
});

export function CardStack({children}) {
    const s = CardStackStyle;
    return <View>
        <View style={s.topCard}>
            {children}
        </View>
        <View style={s.secondCard} />
        <View style={s.thirdCard} />
    </View>
}

function SkeletonCommentInCardStack() {
    return (
        <CardStack>
            <PadBox horiz={12} vert={12}>
                <SkeletonComment />
            </PadBox>
        </CardStack>
    );
}

function SkeletonComment() {
    const s = SkeletonCommentStyle;
    return (
        <View>
            <View style={s.horiz}>
                <SkeletonProfilePicture />
                <SkeletonText width={76} height={7} borderRadius={4} />
            </View>
            <View>
                <Pad size={16} />
                <View >
                    <SkeletonText width={'95%'} />
                    <Pad size={6} />
                    <SkeletonText width={'70%'} />
                </View>
            </View>
            <Pad size={18} />
        </View>
    );
}

const SkeletonCommentStyle = StyleSheet.create({
    horiz: {
        flexDirection: 'row',
        alignItems: 'center',
    },
});

function SkeletonProfilePicture() {
    const s = SkeletonProfilePictureStyle;
    return <View style={s.photo}></View>;
}

const SkeletonProfilePictureStyle = StyleSheet.create({
    photo: {
        width: 24,
        height: 24,
        borderRadius: 16,
        backgroundColor: '#F6F0EC',
        marginRight: 8,
    },
});

function SkeletonText({ width, height, borderRadius }) {
    return (
        <View
            style={{
                height: height || 8,
                borderRadius: borderRadius || 2,
                width: width || '100%',
                backgroundColor: '#F6F0EC',
            }}
        />
    );
}

const CardStackStyle = StyleSheet.create({
    topCard: {
        backgroundColor: 'white',
        boxShadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.05)',
        borderWidth: 1,
        borderColor: colorGreyBorder,
        borderRadius: 6,
        marginBottom: 16,
    },
    secondCard: {
        // backgroundColor: '#faf7f6',
        backgroundColor: 'white',
        boxShadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.05)',
        borderWidth: 1,
        borderColor: colorGreyBorder,
        borderRadius: 6,
        opacity: 0.8,
        zIndex: -1,    
        height: 20,
        position: 'absolute',
        left: 10,
        right: 10,
        bottom: 8
    },
    thirdCard: {
        backgroundColor: 'white',
        boxShadow: '0px 2px 7px 0px rgba(0, 0, 0, 0.05)',
        borderWidth: 1,
        borderColor: colorGreyBorder,
        borderRadius: 6,
        opacity: 0.6,
        zIndex: -2,    
        height: 20,
        position: 'absolute',
        left: 20,
        right: 20,
        bottom: 0    
    }
});