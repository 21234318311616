import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { StyleSheet, View } from 'react-native';

/**
 * @typedef {Object} DimensionData
 * @property {string} width - The desired width of the overlay (e.g., "200px").
 * @property {string} height - The desired height of the overlay (e.g., "150px").
 */


/**
 * @typedef {Object} PositionData
 * @property {string} [left] - The desired left position of the overlay (e.g., "10px"). Optional.
 * @property {string} [top] - The desired top position of the overlay (e.g., "5px"). Optional.
 * @property {string} [right] - The desired right position of the overlay (e.g., "calc(100vw - 200px)"). Optional.
 * @property {string} [bottom] - The desired bottom position of the overlay (e.g., "10px"). Optional.
 */


/**
 * @typedef {Object} PlacementInfo
 * @property {PositionData} position - The current positioning data of the overlay.
 * @property {DimensionData} dimension - The current dimension data of the overlay.
 * @property {function} setPosition - A function to update the positioning data of the overlay.
 * @property {function} setDimension - A function to update the dimension data of the overlay.
 */

/** This component will cause the overlay over the main page's video to be completely invisible. The content beneath will be completely accessible
 * When this component is dismounted the vide overlay becomes visible and interactive again.
*/
export function HiddenOverlay({children}) {
    useEffect(() => {
        // Startup: Hide the overlay over the main video
        window.parent.postMessage({ type: 'psi-change-video-overlay-visibility' , visible: false}, '*');
        return () => {
            window.parent.postMessage({ type: 'psi-change-video-overlay-visibility' , visible: true}, '*');
        }
    },[])
    return <>{children}</>
}


/**  Render this element to show a backdrop over the main page's video element, that is blocking input.
On dismount, the video player will be accessible again.
*/
export function VideoOverlayBackdrop() {
    useEffect(() => {
        window.parent.postMessage({ type: 'psi-show-video-backdrop'}, '*');
        return () => {
            window.parent.postMessage({ type: 'psi-hide-video-backdrop'}, '*');
        }
    },[])
    return <></>
}

/**
 * Base component for overlays. Enables advanced positioning and resizing of its child content.
 * 
 * @param {Object} props - The component props
 * @param {React.ReactNode} props.children - The child elements to be rendered as the overlay content.
 * @param {PositionData} [props.defaultPositionData] (optional) - The default positioning data for the overlay.
 * @param {DimensionData} [props.defaultDimensionData] (optional) - The default dimension data for the overlay.
 * 
 * @returns {JSX.Element} - The JSX element representing the overlay screen.
 */
export function OverlayScreen({children, defaultPositionData={top: "25%", left: "25%"}, defaultDimensionData={width: "50%", height: ""}, repositionIFrameInstead=true}) {
    return <View style={!repositionIFrameInstead ? {position:"absolute",  width: "100%", height: "100%", pointerEvents: "none"} : {}}>
            <OverlayPlacementContextProvider defaultPositionData={defaultPositionData} defaultDimensionData={defaultDimensionData} repositionIframe={repositionIFrameInstead}>
            {children}
        </OverlayPlacementContextProvider>
     </View>
        
}


/**
 * A component that renders its children with specific positioning and sizing based on provided context data adn relative to the parent OverlayScreen
 * When no height is given, the height is automatically set based on the teaser content
 * 
 * @param {Object} props - The component props
 * @param {PositionData} [props.positionData] (optional) - Positioning data (left, top). Defaults to an empty object.
 * @param {DimensionData} [props.dimensionData] (optional) - Dimension data (width, height). Defaults to an empty object.
 * 
 * @returns {JSX.Element} - The JSX element representing the positioned teaser.
 */
export function RepositionOverlay({positionData={left: "", top: ""}, dimensionData={width: "", height: ""}}) {
    const {setPosition, setDimension} = useOverlayPosition()
    useEffect(() => {
        setPosition(positionData)
        setDimension(dimensionData)
        return () => {
            setPosition({})
            setDimension({})
        }
    },[positionData, dimensionData])
    return <></>

}

const OverlayPlacementContext = createContext();
/** This Context Provider holds position data (dimension and position) and reposition/resize methods for an absolutely positioned element within an outer container
 *  This element acts as an outer container. Its children are positioned absolutely within
 *  If repositionIframeInstead is set to true, it will reposition the PSI Iframe instead, enabling a better integration in the video element of an article 
 * */ 
export function OverlayPlacementContextProvider({children, defaultPositionData = {}, defaultDimensionData = {}, repositionIframe=true}) {
    const [position, setPosition] = useState({...defaultPositionData})
    const [dimension, setDimension] = useState({...defaultDimensionData})
    const [dimensionContent, setDimensionContent] = useState({width: "0px", height: null})

    const setNewPosition = (pos) => {
        const posData = {...defaultPositionData, ...pos}
        setPosition(posData)
    }

    const setNewDimension = (dim) => {
        const dimData = {...defaultDimensionData, ...dim}
        setDimension(dimData)
    }

    function onLayout(e) {
        const {height, width} = e.nativeEvent.layout;
        setDimensionContent({height: height + "px", width: width + "px"})
    }
    
    useEffect(() => {
        setNewPosition(defaultPositionData)
        setNewDimension(defaultDimensionData)
    }, [])

    useEffect(() => {
        const posData = Object.assign({left: "", right: "", top: "", bottom: ""}, position)
        window.parent.postMessage({type: 'psi-teaser-position', position: posData}, '*');
    }, [position])


    useEffect(() => {
        const dimData = Object.assign({height: ""}, dimension)

        // If no height is provided, use content's height
        if(!dimData.height) {
            dimData.height = dimensionContent.height
        }
        window.parent.postMessage({type: 'psi-teaser-dimension', dimension: dimData}, '*');
    }, [dimension, dimensionContent])

    const placementInfo = {position, setPosition: setNewPosition, dimension, setDimension: setNewDimension}

    return <OverlayPlacementContext.Provider value={placementInfo}>
        <View style={repositionIframe ? {}: {width: "100%", height:"100%"}}>
            <View onLayout={onLayout} style={repositionIframe ? {}: {position: "absolute", ...position, ...dimension, pointerEvents: "auto"}}>
                {children}
            </View> 
        </View>      
        </OverlayPlacementContext.Provider>
}

/**
 * Custom hook that retrieves the context value from the `OverlayPlacementContext`.
 * This value provides access to the current position, dimension, and setter functions for updating them.
 * When no height is provided, the height is automatically changed based on the content
 * @returns {PlacementInfo} - The object containing information about the overlay's position and dimension.
 */
export const useOverlayPosition = () => {
    return useContext(OverlayPlacementContext);
};