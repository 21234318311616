import { ConversationScreen, Narrow } from "np-platform-client/component/basics";
import { DemoSection } from "np-platform-client/component/demo";
import { Datastore } from "np-platform-client/util/datastore";
import { ReactionTypesWidget } from "../feature/question/ReactionTypesFeature";
import { StructureDemo } from "np-platform-client/util/instance";

export function ReactionTypesComponentDemo() {
    const config = {
        reactionTypes: [
            {label: 'Thank you', emoji: '🙏'}, 
            {label: 'New to me', emoji: '💡'}, 
            {label: 'Respect', emoji: '🤝🏽'}, 
            {label: 'Disagree', emoji: '🤔'},
        ]
    }
    return <ConversationScreen>
        <Narrow>
            <DemoSection label='Reaction Types Widget'>
                <Datastore config={config} >
                    <ReactionTypesWidget comment={{}} />
                </Datastore>
                <Datastore config={config} collections={{reaction: [
                        {reactTo: 1, reactionType: 'Respect'},
                        {reactTo: 1, reactionType: 'Thank you', from: 'a'},
                    ]}}>
                    <ReactionTypesWidget comment={{key: 1}} />
                </Datastore>
            </DemoSection>
        </Narrow>
    </ConversationScreen>
}

const comments = [
    {key: 1, from: 'a', text: 'Godzilla sucks'},
    {key: 2, from: 'b', text: 'Godilla rules'},
    {key: 3, from: 'c', text: 'Godzilla does not exist'},
]

const reactions = [
    {from: 'a', reactTo: 2, reactionType: 'Thank you'},
    {from: 'c', reactTo: 2, reactionType: 'Thank you'},
    {from: 'c', reactTo: 2, reactionType: 'New to me'},
    {from: 'b', reactTo: 1, reactionType: 'Disagree'},
]

const globals = {
    name: 'Is Godzilla Good?',
    questionAuthor: 'a',
    questionTime: Date.now(),
}

export function ReactionTypesFullDemo() {
    const collections = {comment: comments, reaction: reactions}
    return <StructureDemo globals={globals} collections={collections} structureKey='question' features={{reactiontypes: true}} />
}