import { useState } from "react";
import React from "react";
import { ConversationScreen, HeaderBox, HorizBox, Pad, PadBox, WindowTitle } from "np-platform-client/component/basics";
import { CTAButton, SubtleButton, Tag, TextButton } from "np-platform-client/component/button";
import { ActionEdit, ActionReply, BasicComments, CommentsInput, ComposerScreen } from "np-platform-client/component/comment";
import { Heading, UtilityText, Paragraph } from "np-platform-client/component/text";
import { useCollection, useDatastore, useGlobalProperty, usePersonaKey } from "np-platform-client/util/datastore";
import { Image, View } from "react-native";
import { Catcher } from "np-platform-client/component/catcher";
import { useConfig } from "np-platform-client/util/features";
import { colorBlackHover, colorGreyBorder, colorPinkBackground, colorTextGrey, colorWhite } from "np-platform-client/component/color";
import { pushSubscreen } from "np-platform-client/util/navigate";
import { keyToUrl, toBool } from "../util/util";
import { StyleSheet } from "react-native";
import { Byline } from "np-platform-client/component/people";
import { useTranslation } from "np-platform-client/component/translation";
import { Bookmark, Pin, Share } from '@carbon/icons-react';
import { useLogEvent } from "np-platform-client/util/eventlog";
import { TabBar, TabContent, TabNavigation } from "../contrib/zdf/tabnavigation";

// TODO: Bring back ActionReport once it is implemented
export const QuestionStructure = {
    key: 'question',
    name: 'Question',
    screen: QuestionScreen,
    subscreens: {
        composer: ({commentKey, about}) => <ComposerScreen about={about} commentKey={commentKey} intro={<AnswerIntro/>} contentType='Public Response' />,
    },
    defaultConfig: {
        commentActions: [ActionReply],
        commentRightActions: [/* ActionReport, */ ActionEdit],
        commentTopWidgets: [],
        commentAboveWidgets: [],
        commentBelowWidgets: [],
        commentAllowEmpty: false,
        commentEditBottomWidgets: [],
        commentEditTopWidgets: [],
        commentPostBlockers: [],
        commentPostTriggers: [],
        commentInputPlaceholder: 'Share your thoughts...',
        commentReplyPlaceholder: 'Reply to {authorName}...',
        commentInputLoginAction: 'answer',
        commentPostCheckers: [],
        commentFilters: [],
        commentRankers: [],

        replyAboveWidgets: [],
        replyFilters: [],
        replyBoosters: [],

        composerSubtitle: () => 'Public response',
        composerTopBanners: [],
        composerTopWidgets: [],

        topBanners: [],
        pageTopWidgets: [],
        pageBottomWidgets: [],
        pageShowEmptyHelp: true,
        
        noCommentsTitle: 'Start the conversation',
        noCommentsMessage: 'Be the first to share your thoughts',
        noMoreCommentsMessage: 'No more responses',
        currentResponseTitle: () => 'Your current response',
        tabs:[{
            id: "tab-responses",
            label: "All responses",
            component: QuestionCommentSection
        }]
     },
    instance: []
}

function AnswerIntro() {
    const name = useGlobalProperty('name');
    const questionAuthor = useGlobalProperty('questionAuthor');
    const questionTime = useGlobalProperty('questionTime');

    return <View style={{backgroundColor: colorPinkBackground}}>
        <PadBox horiz={20} vert={32}>
            <Heading level={2} strong text={name} />
            <Pad />
            <Byline userId={questionAuthor} time={questionTime} />
            {/* <Heading level={2} text={name} /> */}
        </PadBox>
    </View>
}


// TODO: Maybe migrate this to np-platform?
function MyAnswerPreview({comment}) {
    const s = CommentPreviewStyle;
    const datastore = useDatastore();
    const replies = useCollection('comment', {filter: {replyTo: comment.key}, sortBy: 'time', reverse: true});    
    const upvotes = useCollection('upvote', {filter: {comment: comment.key}});
    const {commentAboveWidgets, commentTopWidgets, commentBelowWidgets, currentResponseTitle} = useConfig();
    const tYourCurrentResponse = useTranslation(currentResponseTitle({comment}));

    const replyCount = replies.length;
    const upvoteCount = upvotes.length;
 
    return <View style={s.outer}>
        <PadBox vert={16} horiz={16}>              
            <Byline type='small' userId={comment.from} name={tYourCurrentResponse} />
            <Pad size={16}/>
            {commentAboveWidgets?.map((Widget,i) => <Widget key={i} comment={comment}/>)}
            {commentTopWidgets?.map((Widget,i) => <Widget key={i} comment={comment}/>)}
            {toBool(comment.text) && <Paragraph numberOfLines={4} text={comment.text?.trim()} />}
            {(upvoteCount > 0 || replyCount > 0) && <Pad size={16} />}
            <HorizBox>
                {upvoteCount > 0 && <UtilityText type='tiny' color={colorTextGrey} label='{count} {noun}' formatParams={{count: upvoteCount, singular: 'upvote', plural: 'upvotes'}} />}
                {upvoteCount > 0 && replyCount > 0 && <UtilityText type='tiny' color={colorTextGrey} label=' • ' />}
                {replyCount > 0 && <UtilityText type='tiny' color={colorTextGrey} label='{count} {noun}' formatParams={{count: replyCount, singular: 'reply', plural: 'replies'}} />}
            </HorizBox>
            {commentBelowWidgets?.map((Widget,i) => <Widget key={i} comment={comment}/>)}
            {commentBelowWidgets && <Pad />}
            {toBool(comment.text) && <Pad size={16} />}
            <CTAButton type='secondary' label='Update your response' onPress={() => datastore.pushSubscreen('composer', {commentKey: comment.key})} />
        </PadBox>
    </View>
}
const CommentPreviewStyle = StyleSheet.create({
    outer: {
        borderColor: colorGreyBorder,
        borderWidth: 1,
        borderRadius: 8,
        backgroundColor: colorWhite
    }
})


function ButtonRow() {
    function onPress() {
        alert('Not implemented')
    }
    return <HorizBox spread>
        <SubtleButton color={colorBlackHover} icon={Pin} label='Community Guidelines' onPress={onPress}/>
        <HorizBox>
            <SubtleButton color={colorBlackHover} icon={Share} label='Share' onPress={onPress}/>
            <Pad />
            <SubtleButton color={colorBlackHover} icon={Bookmark} label='Save' onPress={onPress}/>
        </HorizBox>
    </HorizBox>
}

function QuestionScreen() {
    const {topBanners, myAnswerBannerm, tabs} = useConfig();
    useLogEvent('question-view');
    const name = useGlobalProperty('name');
    const questionAuthor = useGlobalProperty('questionAuthor');
    const questionTime = useGlobalProperty('questionTime');
    const comments = useCollection('comment');
    const personaKey = usePersonaKey();
    const [articlesShown, setArticlesShown] = useState(false);
    const myAnswer = comments.find(comment => comment.from == personaKey && comment.replyTo == null);

    // TODO Remove the parameter directly after navigation
    // Writing an answer causes the tab to be always opened again
    /*
    const url = window.location.href;
    const parsedUrl = new URL(url);
    const query = new URLSearchParams(parsedUrl.search);
    let urlParamTab = query.get("tab")
    */
   let urlParamTab;

    return <ConversationScreen>
        <WindowTitle title={name} />
        {topBanners?.map((Banner, i) => <Banner key={i} />)}
        <HeaderBox>
            {/* <ButtonRow /> */}
            {/* <Pad size={32}/> */}
            <Heading level={1} strong text={name} />
            <Pad />
            <Byline userId={questionAuthor} photoType='large' time={questionTime} />
            <Pad size={32} />
            {myAnswer && <Catcher><MyAnswerPreview comment={myAnswer} /></Catcher>}
            {!myAnswer && <CommentsInput />}
            <Pad size={12} />
        </HeaderBox>
        <TabNavigation tabs={tabs} initialTabId={urlParamTab}>
            <PadBox horiz={20}>
                <TabBar/>
            </PadBox>
            <TabContent/>         
        </TabNavigation>
        
    </ConversationScreen>;
}

function QuestionCommentSection() {
    return <>
        <Catcher>
            <BasicComments canPost={false} />
        </Catcher>
        <Pad size={80} />
    </>
}

export function ArticleCard({article, tags, thumbsize = 64}) {
    const url = keyToUrl(article.key);
    function onPress() {
        window.open(url, '_blank');
    }
    return <HorizBox center={true} key={article.key}>
        <Image source={{uri: article.image}} style={{width: thumbsize, height: thumbsize}} />
        <Pad size={16} />
        <View style={{flex: 1}}>
            {tags ? <HorizBox>{tags.map(tag => <Tag key={tag} color={"white"} label={tag}></Tag>)}</HorizBox>  : <></>}
            <TextButton text={article.title} onPress={onPress}/>
        </View>
    </HorizBox>
}

