
import { DataVizText, TextField, UtilityText } from "np-platform-client/component/text";
import { CTAButton, Tag } from "np-platform-client/component/button";
import { View, StyleSheet } from "react-native";
import { colorGreyHoverBorder, colorGreyPopupBackground, colorPink, colorPinkHover, colorWhite } from "np-platform-client/component/color";
import { useState } from "react";
import { useCollection } from "np-platform-client/util/datastore";
import { useDatastore, usePersonaKey } from "np-platform-client/util/datastore";
import { colorBlack, colorGreyBorder } from "np-platform-client/component/color";
import { IconCheckmark } from "np-platform-client/component/icon";
import { useConfig } from "np-platform-client/util/features";
import { Center, HoverView, PadBox } from "np-platform-client/component/basics";
import { ColoredBall } from "../../../component/colorpill";

export const PollFeature = {
    name: 'Poll',
    key: 'poll',
    config: {
        PollWidget: PollWidget,
    },
    defaultConfig: {
        visualizationType: 'percent'
    }
}

export function PollWidget({ pollKey, question, tagline, resultline, options, pollType, otherOptionValue = null, otherOptionPlaceholder = null }) {
    const datastore = useDatastore();
    const personaKey = usePersonaKey();
    const { visualizationType } = useConfig();
    const optionsLabels = options.map(x => x.label);

    const votes = useCollection(pollKey).map(vote => vote.value.label);
    const myVote = useCollection(pollKey, { filter: { from: personaKey } }).map(vote => vote.value)[0];
    const [isSubmitted, setIsSubmitted] = useState(!!myVote);

    const onVote = (vote, comment = null) => {
        const existing = datastore.getCollection(pollKey, { filter: { from: personaKey } });
        existing.forEach(r => datastore.deleteObject(pollKey, r.key));
        datastore.addObject(pollKey, { from: personaKey, value: { label: vote, ...(comment && { comment }) } });
        setIsSubmitted(true);
    }

    const onChangeVote = () => {
        setIsSubmitted(false);
    }

    return <PollSurveyThenResults
        question={question}
        options={optionsLabels}
        onVote={onVote}
        onChangeVote={onChangeVote}
        votes={votes}
        myVote={myVote?.label}
        comment={myVote?.comment}
        isSubmitted={isSubmitted}
        tagline={tagline}
        resultline={resultline}
        visualizationType={visualizationType}
        pollType={pollType}
        otherOptionValue={otherOptionValue}
        otherOptionPlaceholder={otherOptionPlaceholder}
    />
}

export function PollSurveyThenResults({ question, options, onVote, onChangeVote, votes, myVote, comment = undefined, isSubmitted, visualizationType, pollType = 'MultipleChoice', otherOptionValue = null, otherOptionPlaceholder = null }) {
    const s = PollStyle;

    return <View style={s.pollWrap}>
        <DataVizText type='heading1' label={question || ''} />
        <View style={[s.textField]}>
            <DataVizText type='heading2' label={(isSubmitted) ? 'Your response' : 'Select one option'} />
        </View>
        {!isSubmitted &&
            <>
                {pollType === 'MultipleChoice' &&
                    <PollSurvey
                        options={options}
                        myVote={myVote}
                        visualizationType={visualizationType}
                        onVote={onVote}
                        pollType={pollType}
                    />
                }
                {pollType === 'MultipleChoiceOther' &&
                    <PollSurveyWithTextfield
                        options={options}
                        myVote={myVote}
                        comment={comment}
                        visualizationType={visualizationType}
                        onVote={onVote}
                        pollType={pollType}
                        otherOptionValue={otherOptionValue}
                        otherOptionPlaceholder={otherOptionPlaceholder}
                    />
                }
            </>}
        {isSubmitted &&
            <View>
                {pollType === 'MultipleChoice' &&
                    <>
                        <PollResult
                            options={options}
                            votes={votes}
                            myVote={myVote}
                            onChangeVote={onChangeVote}
                            visualizationType={visualizationType}
                        />
                        <Center><CTAButton wide label='Change my vote' onPress={onChangeVote} /></Center>
                    </>
                }
                {pollType === 'MultipleChoiceOther' &&
                    <>
                        <PollTextResult
                            myVote={myVote}
                            comment={comment}
                        />
                        <Center><CTAButton wide label='Change my vote' onPress={onChangeVote} /></Center>
                    </>
                }
            </View>
        }
    </View>
}

function getPercentAnswer(answer, votes) {
    const sameVote = votes.filter(vote => {
        return vote === answer
    })
    const percent = Math.round((sameVote.length * 100) / votes.length)
    return percent;
}

export function PollSurveyWithTextfield({ options, myVote, comment, showButton = true, onVote, otherOptionValue = '$$Submit your topic$$', otherOptionPlaceholder = '$$Write in a topic...$$' }) {
    const s = PollStyle;
    const [selectedAnswer, setSelectedAnswer] = useState(myVote);
    const [textfieldValue, setTextfieldValue] = useState(comment);

    function onSelect(option) {
        if (showButton) {
            setSelectedAnswer(option)
        } else {
            voteWithComment(option, textfieldValue);
        }
    }

    function voteWithComment(option, comment) {
        if (option !== otherOptionValue) {
            onVote(option, null);
        } else {
            onVote(option, comment);
        }
    }

    return <View style={[s.poll]}>
        <View style={[s.listWrapSurvey]}>
            {options.map((option, idx) =>
                <PollOptionButton
                    key={option}
                    selected={selectedAnswer === option}
                    label={option}
                    onPress={() => onSelect(option)}
                />
            )}
            <PollOptionButtonWithTextfield
                key={'other'}
                selected={selectedAnswer === otherOptionValue}
                label={otherOptionValue}
                inputTextPlaceholder={otherOptionPlaceholder}
                textfieldValue={textfieldValue}
                onTextfieldChange={setTextfieldValue}
                onPress={() => onSelect(otherOptionValue)}
            />
        </View>
        {showButton &&
            <View style={[s.buttonWrap]}>
                <CTAButton disabled={(!selectedAnswer)} wide label='Submit my vote' onPress={() => voteWithComment(selectedAnswer, textfieldValue)} />
            </View>
        }
    </View>
}

export function PollSurvey({ options, optionColors, myVote, showButton = true, onVote }) {
    const s = PollStyle;
    const [selectedAnswer, setSelectedAnswer] = useState(myVote);

    function onSelect(option) {
        if (showButton) {
            setSelectedAnswer(option)
        } else {
            onVote(option)
        }
    }

    return <View style={[s.poll]}>
        <View style={[s.listWrapSurvey]}>
            {options.map((option, idx) =>
                <PollOptionButton
                    key={option}
                    selected={selectedAnswer === option}
                    label={option}
                    color={optionColors?.[idx]}
                    onPress={() => onSelect(option)}
                />
            )}
        </View>
        {showButton &&
            <View style={[s.buttonWrap]}>
                <CTAButton disabled={(!selectedAnswer)} wide label='Submit my vote' onPress={() => onVote(selectedAnswer)} />
            </View>
        }
    </View>
}

export function PollResult({ options, votes, myVote }) {
    const s = PollStyle;

    return <View style={s.poll}>
        <View style={s.listWrapResult}>
            {options.map(option =>
                <PollOptionPercentBar
                    percent={getPercentAnswer(option, votes)}
                    isMine={myVote === option}
                    label={option}
                    key={option}
                />
            )}
        </View>
    </View>
}

const PollTextResultStyle = StyleSheet.create({
    buttonWrap: {
        width: 167
    },
    listWrapSurvey: {
        paddingVertical: 15,
        borderRadius: 8,
        width: '100%',
    },
    poll: {
        alignItems: 'center',
    },
    listWrapResult: {
        paddingHorizontal: 20,
        paddingTop: 20,
        paddingBottom: 10,
        borderRadius: 8,
        backgroundColor: colorWhite,
        width: '100%',
        marginBottom: 24
    },
    textResultWrap: {
        paddingHorizontal: 20,
        paddingTop: 20,
        paddingBottom: 10,
        borderRadius: 8,
        backgroundColor: colorGreyPopupBackground,
        width: '100%',
        marginBottom: 24
    },
    textField: {
        marginTop: 24,
        marginBottom: 12
    },
});

export function PollTextResult({ myVote, comment }) {
    const s = PollTextResultStyle;
    return <View style={s.poll}>
        <View style={s.textResultWrap}>
            <PollOptionButton
                selected
                label={comment ?? myVote}
            />
        </View>
    </View>
}

function PollOptionButton({ label, color, onPress, selected = false, styledNextTopic = false }) {
    const s = PollOptionButtonStyle;

    return <View>
        <HoverView role='button' style={[styledNextTopic ? s.buttonNextTopic : s.button]}
            hoverStyle={s.hover}
            onPress={onPress}>
            {color && <PadBox right={8}><ColoredBall color={color} /></PadBox>}
            <View style={s.body}>
                <UtilityText label={label} />
            </View>
            <View style={[s.rightIcon, selected ? s.iconSelected : '']}>
                <IconCheckmark color='white' />
            </View>
        </HoverView>
    </View>
}


export function PollOptionButtonWithTextfield({ label, color, onPress, inputTextPlaceholder, textfieldValue, onTextfieldChange, selected = false, styledNextTopic = false }) {
    const s = PollOptionButtonStyle;

    return <View>
        <HoverView style={[styledNextTopic ? s.buttonNextTopic : s.button]}
            hoverStyle={s.hover}
            onPress={onPress}>
            {color && <PadBox right={8}><ColoredBall color={color} /></PadBox>}
            <View style={s.body}>
                <UtilityText label={label} />
            </View>
            <View style={[s.rightIcon, selected ? s.iconSelected : '']}>
                <IconCheckmark color='white' />
            </View>
        </HoverView>
        {selected && <TextField disabled={!selected} value={textfieldValue} onChange={onTextfieldChange} placeholder={inputTextPlaceholder} />}
    </View>
}

const PollOptionButtonStyle = StyleSheet.create({
    button: {
        flexDirection: 'row',
        alignSelf: 'flex-start',
        alignItems: 'center',
        paddingRight: 10,
        paddingLeft: 20,
        paddingVertical: 10,
        marginBottom: 12,
        width: "100%",
        borderRadius: 100,
        borderWidth: 1,
        backgroundColor: colorWhite,
        borderColor: colorGreyBorder,
    },

    buttonNextTopic: {
        flexDirection: 'row',
        alignSelf: 'flex-start',
        alignItems: 'center',
        paddingRight: 10,
        paddingLeft: 20,
        paddingVertical: 10,
        width: "100%",
        borderTopWidth: 1,
        borderColor: colorGreyBorder,
    },

    hover: {
        borderColor: colorBlack,
    },
    body: {
        flex: 1
    },
    rightIcon: {
        marginLeft: 10,
        borderColor: colorGreyBorder,
        borderRadius: 100,
        borderWidth: 1,
        padding: 4
    },
    iconSelected: {
        backgroundColor: colorBlack,
        borderColor: colorBlack
    }
})


function PollOptionPercentBar({ label, percent = 0, isMine = true }) {
    const s = PollDataStyles(isMine, percent);

    return <View style={s.container}>
        <View style={s.rowContainer}>
            <View style={s.bar} />
            <View style={s.percentLabel}>
                <UtilityText style={s.percentText} type='small' strong label={percent + '%'} color={colorWhite} />
                <View style={s.tag}>
                    <Tag label='You' color={colorPink} />
                </View>
            </View>
            <View style={{ flex: 100 - percent }}></View>
        </View>
        <UtilityText style={s.percentText} type='tiny' label={label} color={colorWhite} />
    </View>
}

const PollDataStyles = (isMine, percent) => StyleSheet.create({
    container: {
        marginBottom: 8,
        width: "100%",
    },
    rowContainer: {
        flexDirection: 'row',
        marginBottom: 4,
        marginTop: 10,
        width: "100%",
        alignItems: 'center'
    },
    bar: {
        backgroundColor: isMine ? colorPinkHover : colorGreyPopupBackground,
        opacity: isMine ? 1 : 0.3,
        height: 36,
        borderRadius: 4,
        marginRight: 8,
        flex: percent,
        // width: percent+'%',
    },
    tag: {
        marginLeft: 8,
        display: isMine ? 'block' : 'none'
    },
    percentLabel: {
        flexDirection: 'row',
        alignItems: 'center',
        width: 94,
        flexGrow: 0,
        flexShrink: 0
    }
})



// styles
const PollStyle = StyleSheet.create({
    buttonWrap: {
        width: 167
    },
    listWrapSurvey: {
        paddingVertical: 15,
        borderRadius: 8,
        width: '100%',
    },
    poll: {
        alignItems: 'center',
    },
    pollWrap: {
        backgroundColor: colorGreyPopupBackground, 
        padding: '20px',
         borderRadius: '12px',
    },
    listWrapResult: {
        paddingHorizontal: 20,
        paddingTop: 20,
        paddingBottom: 10,
        borderRadius: 8,
        backgroundColor: colorGreyHoverBorder,
        width: '100%',
        marginBottom: 24
    },
    textField: {
        marginTop: 24,
        marginBottom: 12
    },

})



