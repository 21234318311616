import { Heading, TextField, UtilityText } from "np-platform-client/component/text";
import { CTAButton, PopupPanel, ReactionButton, Tag } from "np-platform-client/component/button";
import { useIsAdmin } from "np-platform-client/component/admin";
import { useState } from "react";
import { StyleSheet, View } from "react-native";
import { useCollection, useDatastore, useGlobalProperty, usePersonaKey, useSessionData } from "np-platform-client/util/datastore";
import { Center, FlowBox, HorizBox, Pad, PadBox } from "np-platform-client/component/basics";
import { colorGreyBorder, colorGreyHover, colorGreyPopupBackground, colorPink, colorTextGrey } from "np-platform-client/component/color";
import { needsLogin } from "np-platform-client/structure/login";
import { pushSubscreen } from "np-platform-client/util/navigate";
import { useConfig } from "np-platform-client/util/features";
import { addKey, removeKey } from "np-platform-client/util/util";
import { toBool } from "../../util/util";
import { Modal } from "np-platform-client/component/modal";
import { Checkbox } from "np-platform-client/component/form";

export const RepresentingPerspectivesFeature = {
    name: 'Representing Perspectives',
    key: 'representing_perspectives',
    config: {
        commentAboveWidgets: [PerspectivesForComment],
        composerTopWidgets: [ComposerPerspectiveSelector],
        commentEditTopWidgets: [PerspectivesForComment],
        commentRankers: [{label: 'Boost by perspective', ranker: boostSelectedPerspectives}],
        pageTopWidgets: [MissingPerspectivesCard, PerspectiveFilter],
        pageShowEmptyHelp: false,
    }
}

export function AdminPerspectiveEditorButton() {
    const [modalShown, setModalShown] = useState(false);

    return <View>
        <CTAButton compact type='secondary' label='Edit perspectives (Admin)' onPress={() => setModalShown(true)} />
        {modalShown && <AdminPerspectiveEditorModal onClose={() => setModalShown(false)} />}
    </View>
}

export function AdminPerspectiveEditorModal({onClose}) {
    const datastore = useDatastore();
    const perspectives = useGlobalProperty('perspectives');
    const [editedPerspectives, setEditedPerspectives] = useState(perspectives);

    function onApply() {
        datastore.setGlobalProperty('perspectives', editedPerspectives);
        onClose();
    }

    return <Modal onClose={onClose} buttonRow={<CTAButton wide label='Apply' onPress={onApply} />}>
        <TextField label='Perspective List' big
            value={editedPerspectives} 
            placeholder='What perspectives are important to this conversation? (newline separated)'
            onChange={setEditedPerspectives} />
    </Modal>
}

const emojiPattern = /^(\p{Extended_Pictographic}|\p{Emoji_Presentation})(\u200D(\p{Extended_Pictographic}|\p{Emoji_Presentation}))*/u;
 
function parseEmojiLabelString(inputStr) {  
    const match = inputStr.match(emojiPattern);  
    if (match) {
      const emoji = match[0];
      const text = inputStr.slice(emoji.length).trim();
      return { emoji, text };
    } else {
      return { emoji: null, text: inputStr.trim() };
    }
}

export function PerspectivesForComment({comment}) {
    const commentPerspectives = Object.keys(comment.perspectives || {}).filter(p => comment.perspectives[p]);
    if (!commentPerspectives.length) {
        return null;
    }
    return <PadBox bottom={8}><FlowBox>
        {commentPerspectives.map(perspectiveKey =>
            <PadBox key={perspectiveKey} right={12} bottom={12}>
                <Tag color={colorGreyPopupBackground}
                    text={comment.perspectives?.[perspectiveKey].text} 
                    emoji={comment.perspectives?.[perspectiveKey].emoji} />
            </PadBox>
        )}
    </FlowBox></PadBox>
}

function findPerspectivesPresentInComments(comments) {
    const presentPerspectives = {};
    comments.forEach(comment => {
        Object.keys(comment.perspectives || {}).forEach(perspectiveKey => {
            presentPerspectives[perspectiveKey] = true;
        });
    });
    return presentPerspectives;
}

export function MissingPerspectivesCard() {
    const s = MissingPerspectivesStyle;
    const comments = useCollection('comment', {sortBy: 'time'});
    const perspectives = useGlobalProperty('perspectives');
    const {commentInputLoginAction} = useConfig();
    const personaKey = usePersonaKey();
    const isAdmin = useIsAdmin();
    const datastore = useDatastore();

    const hasCommentByMe = comments.some(c => c.from == personaKey && c.replyTo == null);

    const perspectiveList = (perspectives ?? '').split('\n').filter(x => x);
    const parsedPerspectives = perspectiveList.map(p => parseEmojiLabelString(p));
    const presentPerspectives = findPerspectivesPresentInComments(comments);
    const missingPerspectives = parsedPerspectives.filter(pp => !presentPerspectives[pp.text])
    const allMissing = Object.keys(presentPerspectives).length == 0;
    const noneMissing = missingPerspectives.length == 0;

    return <View style={(allMissing || noneMissing) ? s.genericBox : s.missingBox}>
        <PadBox horiz={16} vert={16}>
            <Center>
                {allMissing ?
                    <Heading center level={2} label="Add your perspective" />
                : noneMissing ?
                    <Heading center level={2} label="Perspectives" />
                :
                    <Heading center level={2} label="We haven't heard from these perspectives yet" />
                }
            </Center>
            <Pad/>
            <FlowBox center>
                {(noneMissing ? parsedPerspectives : missingPerspectives).map(pp =>
                    <PadBox key={pp.text} right={8} bottom={8}>
                        <Tag color={colorGreyPopupBackground} strqong text={pp.text} emoji={pp.emoji} />
                    </PadBox>
                )}
            </FlowBox>
            {!noneMissing && !hasCommentByMe && <PadBox top={12}><Center>
                <CTAButton label='Share your thoughts' compact onPress={datastore.needsLogin(
                    () => datastore.pushSubscreen('composer'), 
                    commentInputLoginAction)} />
                </Center></PadBox>
            }
            {isAdmin && <PadBox top={12}><Center><AdminPerspectiveEditorButton/></Center></PadBox>}
        </PadBox>
    </View>
}
const MissingPerspectivesStyle = StyleSheet.create({
    missingBox: {
        marginHorizontal: 20,
        marginVertical: 32,
        marginTop: 32,
        marginBottom: 28,
        borderRadius: 8,
        backgroundColor: colorPink
    },
    genericBox: {
        marginHorizontal: 20,
        marginTop: 32,
        marginBottom: 28,
        borderRadius: 8,
        borderColor: colorGreyBorder,
        borderWidth: 1,
    }
})

export function ComposerPerspectiveSelector({comment, setComment}) {
    const perspectives = useGlobalProperty('perspectives');
    const perspectiveList = perspectives.split('\n').filter(x => x);
    if (comment.replyTo) {
        return null;
    }
    return <PadBox top={20} bottom={32}>
        <Heading label='How do you relate to this issue?' />
        <Pad size={24} />
        <FlowBox>
            {perspectiveList.map(perspective => 
                <PadBox right={12} bottom={12} key={perspective}>
                    <PerspectiveToggle comment={comment} setComment={setComment} perspective={perspective} />
                </PadBox>
            )}
        </FlowBox>
    </PadBox>
}   

export function PerspectiveToggle({comment, setComment, perspective}) {
    const {emoji, text} = parseEmojiLabelString(perspective);
    const selected = comment?.perspectives?.[text];

    function onPress() {
        if (selected) {
            setComment({...comment, perspectives: {...comment.perspectives, [text]: null}});
        } else {
            setComment({...comment, perspectives: {...comment.perspectives, [text]: {emoji, text}}});
        }
    }

    return <ReactionButton text={text} emoji={emoji}
        selected={selected} onPress={onPress}
    />
}

export function PerspectiveFilterPopup({savedSelected, onClose}) {
    const perspectives = useGlobalProperty('perspectives');
    const datastore = useDatastore();
    const [currentSelected, setCurrentSelected] = useState(savedSelected);
    const perspectiveList = (perspectives ?? '').split('\n').filter(x => x).map(p => parseEmojiLabelString(p));

    function togglePerspective(text) {
        setCurrentSelected(currentSelected?.[text] ? removeKey(currentSelected, text) : addKey(currentSelected, text));
    }

    function onApply() {
        datastore.setSessionData(['representingPerspectives', 'selected'], currentSelected);
        onClose();
    }

    return <View>
        <UtilityText label='Sort by perspective' type='tiny' caps />
        <Pad size={12}/>
        <UtilityText label='What perspectives do you want to see first?' type='small' color={colorTextGrey} />
        <Pad size={12} />
        {perspectiveList.map(p => 
            <Checkbox emoji={p.emoji} text={p.text} key={p.text}
                value={currentSelected?.[p.text]}
                onChange={() => togglePerspective(p.text)} />
            )}
        <Pad />
        <CTAButton wide label='Apply' onPress={onApply} disabled={Object.keys(currentSelected || {}).length === 0}/>
    </View>
}

export function PerspectiveFilter() {
    const savedSelected = useSessionData(['representingPerspectives', 'selected']);
    const comments = useCollection('comment');

    if (comments.length == 0) {
        return null;
    }

    const [hover, setHover] = useState(false);
    const count = Object.keys(savedSelected || {}).length || 0;

    function popupContent({onClose}) {
        return <PerspectiveFilterPopup savedSelected={savedSelected} onClose={onClose} />;
    }

    return <PadBox horiz={20} top={12} bottom={12}>
        <PopupPanel popupContent={popupContent} hover={hover} setHover={setHover}>
            <PadBox vert={8}>
                <HorizBox center>
                    <UtilityText strong label='Sort by perspective' underline={hover} />
                    {toBool(count) && <PadBox left={8}><CircleCount count={count} /></PadBox>}
                </HorizBox>
            </PadBox>
        </PopupPanel>
    </PadBox>
}

// TODO: Use the new version moved to np-platform
export function CircleCount({count}) {
    const s = CircleCountStyle;
    return <View style={s.circle}>
        <UtilityText strong text={String(count)} />
    </View>
}
const CircleCountStyle = StyleSheet.create({
    circle: {
        backgroundColor: colorGreyHover,
        borderRadius: 100,
        width: 20,
        height: 20,
        justifyContent: 'center',
        alignItems: 'center'
    }
});

function boostSelectedPerspectives({datastore, comments}) {
    const allSelected = datastore.getSessionData(['representingPerspectives', 'selected']);
    const selectedLabels = Object.keys(allSelected || {});

    const topComments = comments.filter(comment => selectedLabels.some(text => comment.perspectives?.[text]));
    const otherComments = comments.filter(comment => !selectedLabels.some(text => comment.perspectives?.[text]));

    return [...topComments, ...otherComments];
}

