const { Narrow } = require("np-platform-client/component/basics");
const { DemoHeader } = require("np-platform-client/component/demo");
const { Paragraph, UtilityText } = require("np-platform-client/component/text");
const { VideoPlayerStateContextProvider, useVideo } = require("../videoPlayerStateContext");
const { SimpleVideo } = require("../videoPlayer");
const { View } = require("react-native-web");
const { CTAButton } = require("np-platform-client/component/button");


export function VideoPlayerStateContextProviderDemo() {
    return (
        <Narrow>
            <DemoHeader label="Example use of the VideoPlayerStateContext" />
            <Paragraph text="Setting useVideoFromArticle enables the control of the main video on the article site" />
            <VideoPlayerStateContextProvider useVideoFromArticle={false}>
                <SimpleVideo
                    src={"https://archive.org/download/BigBuckBunny_124/Content/big_buck_bunny_720p_surround.mp4"}
                />
                <SimpleVideoControls />
            </VideoPlayerStateContextProvider>
        </Narrow>
    );
}

function SimpleVideoControls() {
    const video = useVideo();
    return (
        <View>
            <CTAButton label="PLAY" onPress={video.playVideo}></CTAButton>
            <CTAButton label="PAUSE" onPress={video.pauseVideo}></CTAButton>
            <UtilityText label={"Duration: " + video.duration}></UtilityText>
            <UtilityText label={"Current Time: " + video.currentTime}></UtilityText>
            <UtilityText label={"Is Playing?: " + video.isPlaying}></UtilityText>
            <UtilityText label={"Ended: " + video.ended}></UtilityText>
        </View>
    );
}