import { CHOOSEONE, COMPOSITE, SECTION } from "np-platform-client/feature";
import { imagesZdf } from "../../contrib/zdf/assets";

export const ConversationHelperCommentBasedTimingFeature = {
    name: 'Automatic analysis',
    parentFeature: 'conversationhelper',
    key: 'conversationhelper_timing_comments',
    config: {
        timingSetting: {
            type: "comment",
            every: 1
        }
    }
};

export const ConversationHelperManualTimingFeature = {
    name: 'Only manual analysis',
    parentFeature: 'conversationhelper',
    key: 'conversationhelper_timing_manual',
    config: {
        timingSetting: {
            type: "manual",
            every: 1
        }
    }
};

export const ConversationHelperCommentBasedTimingFeature1 = {
    name: '1 comment',
    parentFeature: 'conversationhelper_timing_comments',
    key: 'conversationhelper_timing_comments_1',
    config: {
        timingSetting: {
            type: "comment",
            every: 1
        }
    }
};

export const ConversationHelperCommentBasedTimingFeature2 = {
    name: '3 comments',
    parentFeature: 'conversationhelper_timing_comments',
    key: 'conversationhelper_timing_comments_2',
    config: {
        timingSetting: {
            type: "comment",
            every: 3
        }
    }
};

export const ConversationHelperCommentBasedTimingFeature3 = {
    name: '5 comments',
    parentFeature: 'conversationhelper_timing_comments',
    key: 'conversationhelper_timing_comments_3',
    config: {
        timingSetting: {
            type: "comment",
            every: 5
        }
    }
};

export const ConversationHelperCommentBasedTimingFeature4 = {
    name: '10 comments',
    parentFeature: 'conversationhelper_timing_comments',
    key: 'conversationhelper_timing_comments_4',
    config: {
        timingSetting: {
            type: "comment",
            every: 10
        }
    }
};

// Once we figure out which configurations to test we can modify these personalities
export const ConversationHelperPersonalityFeatureA = {
    name: 'Personality A',
    parentFeature: 'conversationhelper',
    key: 'conversationhelper_personality_a',
    config: {
        conversationHelperAppearance: {
            name: "Auto-Moderator",
            photoUrl: imagesZdf.conversationhelperProfileCallcenter
        },
        moderatorType: "a"
    }
};

export const ConversationHelperPersonalityFeatureB = {
    name: 'Personality B',
    parentFeature: 'conversationhelper',
    key: 'conversationhelper_personality_b',
    config: {
        conversationHelperAppearance: {
            name: "Facilitator",
            photoUrl: imagesZdf.conversationhelperProfileFacilitator
        },
        moderatorType: "b"
    }
};

export const ConversationHelperPersonalityFeatureC = {
    name: 'Personality C',
    parentFeature: 'conversationhelper',
    key: 'conversationhelper_personality_c',
    config: {
        conversationHelperAppearance: {
            name: "Rule Keeper",
            photoUrl: imagesZdf.conversationhelperProfileBulb
        },
        moderatorType: "c"
    }
};

export const ConversationHelperScopeAllFeature = {
    name: 'All comments',
    parentFeature: 'conversationhelper',
    key: 'conversationhelper_scope_all_comments',
    config: {
        analysisScope: "allComments"
    }
};

export const ConversationHelperScopeRecentFeature = {
    name: 'Most recent x% of comments',
    parentFeature: 'conversationhelper',
    key: 'conversationhelper_scope_recent_comments',
    config: {
        analysisScope: "selectTopXPercent"
    }
};

export const ConversationHelperScopeNewFeature = {
    name: 'New comments since last analysis',
    parentFeature: 'conversationhelper',
    key: 'conversationhelper_scope_new_comments',
    config: {
        analysisScope: "newCommentsSinceLastAnalysis"
    }
};

export const ConversationHelperScopeThresholdFeature1 = {
    name: "Always",
    parentFeature: "conversationhelper",
    key: "conversationhelper_scope_threshold_1",
    config: {
        minCommentThreshold: 1
    }
}

export const ConversationHelperScopeThresholdFeature2 = {
    name: "After 5 comments",
    parentFeature: "conversationhelper",
    key: "conversationhelper_scope_threshold_2",
    config: {
        minCommentThreshold: 5
    }
}

export const ConversationHelperScopeThresholdFeature3 = {
    name: "After 10 comments",
    parentFeature: "conversationhelper",
    key: "conversationhelper_scope_threshold_3",
    config: {
        minCommentThreshold: 10
    }
}

export const ConversationHelperTopXPercentFeature1= {
    name: "Most recent 10%",
    parentFeature: "conversationhelper",
    key: "conversationhelper_top_x_percent_1",
    config: {
        topXPercentFloat: 0.1
    }
}

export const ConversationHelperTopXPercentFeature2= {
    name: "Most recent 25%",
    parentFeature: "conversationhelper",
    key: "conversationhelper_top_x_percent_2",
    config: {
        topXPercentFloat: 0.25
    }
}

export const ConversationHelperTopXPercentFeature3= {
    name: "Most recent 50%",
    parentFeature: "conversationhelper",
    key: "conversationhelper_top_x_percent_3",
    config: {
        topXPercentFloat: 0.5
    }
}

export const GuidanceUpdateIntervalFeature = {
    name: "Update guidance if there are no problems",
    key: "conversationhelper_guidance_update_interval",
    parent: "conversationhelper",
    config: {
        guidanceUpdateIntervalEnabled: true,
    }
}

export const GuidanceUpdateCommentIntervalFeature0 = {
    name: "Never",
    key: "conversationhelper_guidance_update_comment_interval_0",
    parent: "conversationhelper_guidance_update_interval",
    config: {
        guidanceUpdateCommentInterval: null
    }
}

export const GuidanceUpdateCommentIntervalFeature1 = {
    name: "Every comment",
    key: "conversationhelper_guidance_update_comment_interval_1",
    parent: "conversationhelper_guidance_update_interval",
    config: {
        guidanceUpdateCommentInterval: 1
    }
}

export const GuidanceUpdateCommentIntervalFeature2 = {
    name: "Every 3 comments",
    key: "conversationhelper_guidance_update_comment_interval_2",
    parent: "conversationhelper_guidance_update_interval",
    config: {
        guidanceUpdateCommentInterval: 3
    }
}

export const GuidanceUpdateCommentIntervalFeature3 = {
    name: "Every 5 comments",
    key: "conversationhelper_guidance_update_comment_interval_3",
    parent: "conversationhelper_guidance_update_interval",
    config: {
        guidanceUpdateCommentInterval: 5
    }
}

export const GuidanceUpdateCommentIntervalFeature4 = {
    name: "Every 10 comments",
    key: "conversationhelper_guidance_update_comment_interval_4",
    parent: "conversationhelper_guidance_update_interval",
    config: {
        guidanceUpdateCommentInterval: 10
    }
}

export const GuidanceUpdateTimeIntervalFeature0 = {
    name: "Never",
    key: "conversationhelper_guidance_update_time_interval_0",
    parent: "conversationhelper_guidance_update_interval",
    config: {
        guidanceUpdateTimeInterval: null
    }
}


export const GuidanceUpdateTimeIntervalFeature1 = {
    name: "Immediately",
    key: "conversationhelper_guidance_update_time_interval_1",
    parent: "conversationhelper_guidance_update_interval",
    config: {
        guidanceUpdateTimeInterval: 1
    }
}

export const GuidanceUpdateTimeIntervalFeature2 = {
    name: "Every 10 min",
    key: "conversationhelper_guidance_update_time_interval_2",
    parent: "conversationhelper_guidance_update_interval",
    config: {
        guidanceUpdateTimeInterval: 10 * 60 * 1000// converts to miliseconds
    }
}

export const GuidanceUpdateTimeIntervalFeature3 = {
    name: "Every 30 min",
    key: "conversationhelper_guidance_update_time_interval_3",
    parent: "conversationhelper_guidance_update_interval",
    config: {
        guidanceUpdateTimeInterval: 30 * 60  * 1000// converts to miliseconds
    }
}

export const GuidanceUpdateTimeIntervalFeature4 = {
    name: "Every hour",
    key: "conversationhelper_guidance_update_time_interval_4",
    parent: "conversationhelper_guidance_update_interval",
    config: {
        guidanceUpdateTimeInterval: 60 * 60 * 1000 // converts to miliseconds
    }
}

export const ConversationHelperSettingsFeatures = [
    SECTION('Timing of Analysis', [
        // TODO: How can we make automatic analysis and manual analysis an "exclusive or"? Being able to turn both on is confusing and doesn't make sense. CHOOSEONE can't have a sub-menu though.
        COMPOSITE(ConversationHelperCommentBasedTimingFeature, [
            CHOOSEONE('Analyze after every...', [
                ConversationHelperCommentBasedTimingFeature1,
                ConversationHelperCommentBasedTimingFeature2,
                ConversationHelperCommentBasedTimingFeature3,
                ConversationHelperCommentBasedTimingFeature4,
            ]),
        ]),
        ConversationHelperManualTimingFeature,
        COMPOSITE(GuidanceUpdateIntervalFeature, [
            CHOOSEONE("Update based on comments...", [
                GuidanceUpdateCommentIntervalFeature0,
                GuidanceUpdateCommentIntervalFeature1,
                GuidanceUpdateCommentIntervalFeature2,
                GuidanceUpdateCommentIntervalFeature3,
                GuidanceUpdateCommentIntervalFeature4,
            ]),
            CHOOSEONE("Update based on time...", [
                GuidanceUpdateTimeIntervalFeature0,
                GuidanceUpdateTimeIntervalFeature1,
                GuidanceUpdateTimeIntervalFeature2,
                GuidanceUpdateTimeIntervalFeature3,
                GuidanceUpdateTimeIntervalFeature4
            ])
        ]),
    ]),
    SECTION('Scope of Analysis', [
        CHOOSEONE('Analyze...', [
            ConversationHelperScopeAllFeature,
            ConversationHelperScopeRecentFeature,
            ConversationHelperScopeNewFeature,
        ]),
        // TODO: Is there a way to only show these options if the most recent comments option from above is selected?
        CHOOSEONE('Use percentage...',[
            ConversationHelperTopXPercentFeature1,
            ConversationHelperTopXPercentFeature2,
            ConversationHelperTopXPercentFeature3
            
        ]),
        CHOOSEONE("Apply percentage...", [
            ConversationHelperScopeThresholdFeature1,
            ConversationHelperScopeThresholdFeature2,
            ConversationHelperScopeThresholdFeature3
        ])
    ]),
    // SECTION("Update Guidance At Latest", [
    //     CHOOSEONE("Update based on comments...", [
    //         GuidanceUpdateCommentIntervalFeature0,
    //         GuidanceUpdateCommentIntervalFeature1,
    //         GuidanceUpdateCommentIntervalFeature2,
    //         GuidanceUpdateCommentIntervalFeature3,
    //     ]),
    //     CHOOSEONE("Update based on time...", [
    //         GuidanceUpdateTimeIntervalFeature0,
    //         GuidanceUpdateTimeIntervalFeature1,
    //         GuidanceUpdateTimeIntervalFeature2,
    //         GuidanceUpdateTimeIntervalFeature3,
    //         GuidanceUpdateTimeIntervalFeature4
    //     ])
    // ]),
    SECTION('Auto-Moderator Personality', [
        CHOOSEONE('Use personality...', [
            ConversationHelperPersonalityFeatureA,
            ConversationHelperPersonalityFeatureB,
            ConversationHelperPersonalityFeatureC
        ])
    ]),
]
