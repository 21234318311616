import { StructureDemo } from "np-platform-client/util/instance";

export function ProfileSettingsFullDemo() {
    const globals = { initialized: true };

    return (
        <StructureDemo
            globals={globals}
            structureKey="profile"
            instanceKey='a'
            features={{ profilesettings: true, profilevideovotingsetting: true }}
        />
    );
}