import { fbKeyToString } from "np-platform-client/util/firebase";

export function keyToUrl(key) {
    return fbKeyToString(decodeURIComponent(key));
}

export function toBool(arg) {
    if (arg) {
        return true;
    } else {
        return false;
    }
}