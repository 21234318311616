import { UtilityText } from "np-platform-client/component/text";
import { useEffect, useState } from "react";
import { View } from "react-native";

export function useCountdown(timer = 60, interval = 1000) {
    const [count, setCount] = useState(timer);
    const [countdownFinished, setCountDownFinished] = useState(false)

    useEffect(() => {
        if (count <= 0) {
            setCount(0)
            setCountDownFinished(true)
            return;
        }

        const timerId = setInterval(() => {
            setCount(prevCount => prevCount - interval / 1000);
        }, interval);

        return () => clearInterval(timerId);
    }, [count]);

    return { count, countdownFinished, setCount }
}

/**
 * A circular countdown element which runs down in the given time and executes a callback
 */
export function VideoVotingCountdownElement({
    size = 48,
    circleRadius = 18,
    strokeColor = '#484848',
    strokeBackgroundColor = "#00000040",
    strokeWidth = 4,
    timer,
    callback }) {
    const { count, countdownFinished, setCount } = useCountdown(timer, 100);
    useEffect(() => {
        if (!countdownFinished) return;
        if (callback) {
            callback()
        }

    }, [countdownFinished])

    useEffect(()=>{
        setCount(timer)
    },[timer])

    return <CircularBar percentage={count * 100 / timer}
        size={size} circleRadius={circleRadius}
        strokeColor={strokeColor}
        strokeBackgroundColor={strokeBackgroundColor}
        strokeWidth={strokeWidth}>
        <UtilityText text={Math.ceil(count).toString()}></UtilityText>
    </CircularBar>
}


export function CircularBar({
    children,
    size = 50,
    circleRadius = 20,
    strokeColor = '#484848',
    strokeBackgroundColor = "#00000040",
    strokeWidth = 5,
    percentage = 100
}) {

    const radius = circleRadius;
    const circumference = 2 * Math.PI * radius;
    const arcLength = (percentage / 100) * circumference;

    return (
        <View style={[{ width: size, height: size }]}>
            <View style={{ width: "100%", height: "100%", justifyContent: 'center', alignItems: 'center' }}>
                {children}
            </View>
            <View style={{ position: "absolute" }}>
                <svg height={size} width={size} viewBox={`0 0 ${size} ${size}`}>
                    <path
                        d={`
                            m ${size / 2},${size / 2 - radius}
                            a ${radius},${radius},0,0,0,0,${2 * radius}
                            a ${-radius},${-radius},0,0,0,0,${-2 * radius}
                            z
                        `}
                        fill="none"
                        stroke={strokeBackgroundColor}
                        strokeWidth={strokeWidth}
                        strokeLinecap="butt"
                    />
                    <path
                        d={`
                            m ${size / 2},${size / 2 - radius}
                            a ${radius},${radius},0,0,0,0,${2 * radius}
                            a ${-radius},${-radius},0,0,0,0,${-2 * radius}
                            z
                        `}
                        fill="none"
                        stroke={strokeColor}
                        strokeWidth={strokeWidth}
                        strokeLinecap="butt"
                        strokeDasharray={`${arcLength}, ${circumference}`}
                    />
                </svg>
            </View>
        </View>
    );
}