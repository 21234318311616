import { DataVizText } from "np-platform-client/component/text";
import { PollSurvey } from "../feature/cbcrc/poll/poll";
import { View } from "react-native";
import { useState } from "react";
import { ColoredBallPill } from "./colorpill";
import { TextButton } from "np-platform-client/component/button";
import { HorizBox, Pad } from "np-platform-client/component/basics";

// Shows a poll survey that collapses to just show your vote once selected
// Currently used in an experimental test of Comment Slider.

export function PollPicker({value, options, optionColors, placeholder, onChangeValue}) {
    const [expanded, setExpanded] = useState(!value);

    function onVote(option) {
        const value = options.indexOf(option) + 1;
        setExpanded(false);
        onChangeValue(value)
    }

    if (expanded) {
        return <View>
            <DataVizText type='heading2' label={placeholder} />
            <PollSurvey options={options} optionColors={optionColors} myVote={value} showButton={false} onVote={onVote} />
        </View>
    } else {
        return <HorizBox center>
            <ColoredBallPill label={options[value - 1]} color={optionColors[value - 1]} />
            <Pad size={10} />
            <TextButton type='tiny' label='Change' onPress={() => setExpanded(true)} />
        </HorizBox>
    }
}

