export const STATIC_TOPIC = {
    name: 'How artificial intelligence impacts your life?',
    status: 'live', // infer from start/end at?
    startAt: '2024-06-01',
    endAt: '2024-06-30',
    conversationStarter: 'Submit your question about AI to our panel of experts',
    poll: {
        question: 'How do you feel about the increasing use of AI in everyday life?',
        resultline: 'Your response',
        tagline: 'Select one option',
        type: 'MultipleChoice',
        showDataviz: true,
        options: [ {label: 'Very positive'},{label: 'Neutral'},{label: 'somewhat negative'}, {label: 'Very negative'}],
    },
    relatedConversations: [
        {
            id: 'Vzi0jr8KJPwg',
            text: 'What do you think about XYZ',
            picture: 'https://placehold.co/250x150',
        },
        {
            id: 'JwirywJwGAMW',
            text: 'What do you think about XYZ',
            picture: 'https://placehold.co/250x150',
        },
        {
            id: '8hTlMegzCe21',
            text: 'What do you think about XYZ',
            picture: 'https://placehold.co/250x150',
        }
    ],
    relatedTopics: [
        {
            id: 'Vzi0jr8KJPwg',
            text: 'What do you think about XYZ',
            picture: 'https://placehold.co/250x150',
            live: true,
            startAt: '2024-06-01',
            endAt: '2024-06-30',
        },
        {
            id: 'JwirywJwGAMW',
            text: 'What do you think about XYZ',
            picture: 'https://placehold.co/250x150',
            live: false,
            startAt: '2024-06-01',
            endAt: '2024-06-30',
        },
        {
            id: '8hTlMegzCe21',
            text: 'What do you think about XYZ',
            picture: 'https://placehold.co/250x150',
            live: false,
            startAt: '2024-06-01',
            endAt: '2024-06-30',
        }
    ],
    relatedContents: [
        {
            text: 'Le recours à l’IA en hausse dans la population canadienne',
            picture: 'https://images.radio-canada.ca/q_auto,w_1200/v1/ici-premiere/16x9/mdr-robot-conversationnel.jpg',
            type: 'text',
            url: '/nouvelle/2048276/ia-canada-sondage-hausse-utilisation',
            id: 1,
        },
        {
            text: 'Google et la gestion de la circulation : la Ville espère des économies',
            picture: 'https://images.radio-canada.ca/q_auto,w_1200/v1/ici-info/16x9/gestionnaire-arteriel.jpg',
            type: 'text',
            url: '/nouvelle/2046551/intelligence-artificielle-circulation-automobile-gestion-arterielle',
            id: 2,
        },
        {
            text: 'Des artistes poursuivent OpenAI, accusée d’avoir utilisé leurs livres pour ChatGPT',
            picture: 'https://images.radio-canada.ca/q_auto,w_1200/v1/ici-info/16x9/ai-intelligence-artificielle-chatgpt.jpg',
            type: 'text',
            url: '/nouvelle/1995206/livres-chatgpt-openai-poursuite',
            id: 3,
        },
        {
            text: 'Prédire les complications du cancer grâces à L’IA',
            picture: 'https://images.radio-canada.ca/v1/ici-tele/16x9/decouverte-sante-ia-intelligence-artificielle-4-fevrier-2024.jpg',
            type: 'media',
            url: '/info/videos/1-8887402/predire-complications-cancer-graces-a-ia',
            id: 4,
        },
    ],
    testContents: [
        {
            id: '3ldrP0ctarks-test',
            text: "Prédire les complications du cancer grâce à l'IA",
            picture: 'https://placehold.co/250x150',
            type: 'media',
        },
    ],
    nextTopicPoll: {
        question: 'Which topic would you like us to cover next?',
        tagline: 'Vote on one topic',
        resultLine: 'Your vote',
        type: 'MultipleChoiceOther',
        showDataviz: false,
        otherOptionValue: 'Submit your topic',
        otherOptionPlaceholder: 'Write in a topic...',
        options: [{ label:  'War in Ukraine' },
                { label: 'Violence in Toronto' },
                { label: 'Immigration protest' },
                { label: 'First nation leadership'},
            ],
    },
}
