const defaultGlobals = {
    name: "How can we help refugees from Ukraine?",
    questionAuthor: "a",
    questionTime: Date.now() - 5000000,
    lastConversationAnalysisTime: Date.now() - 5000000
};

// TODO: I don't know why the time I add here seems to have no effect on the demo
const defaultThoughtProvokingPrompts = [
    {
        key: "tpp1",
        suggestedPerspective: "Let's share our ideas on how we can support refugees from Ukraine!",
        reminder: "Every voice counts, so let's create a space for understanding and compassion as we exchange our thoughts.",
        time: Date.now() - 5000000,
    },
    {
        key: "tpp2",
        suggestedPerspective: "Exploring the role of local communities in providing emotional support and integration for refugees could enrich this discussion.",
        reminder: "Focusing on constructive feedback and acknowledging the efforts of others can foster a more positive and collaborative environment.",
        time: Date.now() - 2500000,
    },
    {
        key: "tpp3",
        suggestedPerspective: "Exploring successful integration programs from other countries could provide valuable insights for supporting Ukrainian refugees.",
        reminder: "Focus on sharing constructive feedback and empathetic responses to foster a more inclusive and productive dialogue.",
        time: Date.now(),
    },
];

const defaultComments = [
    {
        key: 1,
        from: "a",
        text: "The community center in my area is offering free German classes for refugees now. I think teaching our language is the best way to help integrate refugees",
    },
    {
        key: 2,
        from: "b",
        replyTo: 1,
        text: "So you're saying I'm supposed to teach German classes now? As if that was a realistic thing to ask of people. I have a full-time job and I'm not a teacher",
    },
    {
        key: 3,
        from: "c",
        text: "I don't want any refugees in my city. We don't have the space or money to accommodate them",
    },
];

const defaultReactions = [
    {
        key: "r0",
        from: "a",
        reactTo: "tpp1",
        reactionType: "Respect"
    },
    {
        key: "r1",
        from: "b",
        reactTo: "tpp1",
        reactionType: "Disagree"
    },
    {
        key: "r2",
        from: "a",
        reactTo: "tpp3",
        reactionType: "Respect"
    },
    {
        key: "r3",
        from: "b",
        reactTo: "tpp3",
        reactionType: "Disagree"
    }
]

const defaultReactionTypes = [
    { label: "Thank you", emoji: "🙏" },
    { label: "New to me", emoji: "💡" },
    { label: "Respect", emoji: "🤝🏽" },
    { label: "Disagree", emoji: "🤔" },
]

const checkForProblemsInConversationDemo = ({datastore, comments = "", topic, callType, timingSetting, moderatorType}) => {
    datastore.setGlobalProperty("lastConversationAnalysisTime", Date.now())
    // For demo purposes a conversation is deemed problematic if it contains the word 'bad'
    return {
        problemsFound: comments.includes("bad"),
        explanation: "Analysis of the conversation"
    }
}

const generateThoughtProvokingPromptFromConversationDemo = ({datastore, comments, analysis}) => {
    const fakePrompt = {
        question: "New thoughtful prompt question",
        reminder: "A new reminder",
        explanation: analysis
    }

    datastore.addObject('thought_provoking_prompt', fakePrompt)

    return {}
}

const fakeEventLog = ({datastore, eventType}) => {
    console.log(eventType)
}

const serverCalls = {
    conversationhelper: {
        checkForProblemsInConversation: checkForProblemsInConversationDemo,
        generateThoughtProvokingPromptFromConversation: generateThoughtProvokingPromptFromConversationDemo
    },
    eventlog: {
        logEvent: fakeEventLog
    }
}

const summaries = [
    {
        key: "s0",
        suggestedPerspective:"Exploring successful integration programs from other countries could provide valuable insights for supporting Ukrainian refugees.",
        summary: "This conversation discusses the integration of refugees, with one person supporting free German classes as a way to help refugees integrate into the community. However, there are contrasting opinions, with another individual expressing frustration at the idea of personal involvement in teaching, while a third person opposes the presence of refugees altogether, citing concerns about space and financial resources in the city.",
        time: Date.now()
    },
    {
        key: "s1",
        suggestedPerspective:"How sustainable is the current level of international aid for Ukrainian refugees, and what should be prioritized moving forward?",
        summary: "This conversation discusses the integration of refugees, with one person supporting free German classes as a way to help refugees integrate into the community. However, there are contrasting opinions, with another individual expressing frustration at the idea of personal involvement in teaching, while a third person opposes the presence of refugees altogether, citing concerns about space and financial resources in the city.",
        time: Date.now()
    },
    {
        key: "s2",
        suggestedPerspective:"Is Europe doing enough to support Ukrainian refugees, or could more be done?",
        summary: "This conversation discusses the integration of refugees, with one person supporting free German classes as a way to help refugees integrate into the community. However, there are contrasting opinions, with another individual expressing frustration at the idea of personal involvement in teaching, while a third person opposes the presence of refugees altogether, citing concerns about space and financial resources in the city.",
        time: Date.now()
    }
]

export const conversationHelperDemoData = {
    globals: defaultGlobals,
    comments: defaultComments,
    thoughtProvokingPrompts: defaultThoughtProvokingPrompts,
    reactions: defaultReactions,
    reactionTypes: defaultReactionTypes,
    serverCalls: serverCalls,
    summaries: summaries
}