import { useIsAdmin } from "np-platform-client/component/admin";
import { Banner } from "np-platform-client/component/banner";
import { Pad, Separator } from "np-platform-client/component/basics";
import { CTAButton } from "np-platform-client/component/button";
import { Heading, UtilityText } from "np-platform-client/component/text";
import { useDatastore, useGlobalProperty, useInstanceKey, useStructureKey } from "np-platform-client/util/datastore";
import { useConfig } from "np-platform-client/util/features";
import { gotoInstance, gotoInstanceScreen, makeUrl } from "np-platform-client/util/navigate";
import { useEffect } from "react";
import { View } from "react-native";
import { useGlobalQuestions } from "../../../component/question";
import { TeaserScreen } from "../../../component/teaser";
import { colorPinkBackground } from "np-platform-client/component/color";
import { endometriosisVideoVotingTemplateDE, endometriosisVideoVotingTemplateEN, howWeWantToLoveVideoVotingTemplateDE, howWeWantToLoveVideoVotingTemplateEN } from "./examples/votingtemplate";

// TODO: Make this a generic function for everyone
// There was no possibility to change the url of the teaser Iframe
export function gotoUrlTeaser({structureKey, instanceKey, screenKey, targetTeaser=null}) {
    const url = makeUrl([structureKey, instanceKey, screenKey]);
    window.parent.postMessage({type: 'psi-teaser-gotoUrl', url, targetTeaser}, '*');
}

export const VideoVotingFeature = {
    name: 'Video Voting',
    key: 'videovotingArticle',
    config: {
        teaser: VideoVotingTeaser,
        adminScreen: DefaultTemplateCreation,
    },
    defaultConfig: {
        showVotingWithinTextTeaser: false
    }
}


// Determines if the video voting should be shown on top of the video or below it in the teaser Iframe
export const VideoVotingVideoOverlayFeature = {
    name: 'Video Voting - Show video voting within the article text',
    key: 'videovotingArticleTextIntegration',
    parentFeature: 'videovotingArticle',
    config: {},
    defaultConfig: {
        showVotingWithinTextTeaser: true
    }
}

// This teaser feature just redirects the teaser to the videovoting structure if video voting is enabled
// TODO Default video voting creation is done here. Needs to be removed once the video voting editor is ready
function VideoVotingTeaser() {
    const isAdmin = useIsAdmin();
    const structureKey = useStructureKey();
    const instanceKey = useInstanceKey();
    const {showVotingWithinTextTeaser} = useConfig()

    const videoVotingEnabled = useGlobalProperty("videoVotingEnabled");
    const questionkey = useGlobalProperty("questionThreadKey");
    console.log("videoVotingEnabled:", videoVotingEnabled);

    useEffect(() => {
        if(videoVotingEnabled){
            // TODO: Instead of using the instancekey of the article we shoud use a videovoting instance key
            // Wait until the proper voting editor is implemented
            // Which will be assigned to the article
            gotoUrlTeaser({structureKey: "videovoting", instanceKey, screenKey: "teaser", targetTeaser: !showVotingWithinTextTeaser ? "teaser-video" : null})
        }
    }, [])


    const openVideoVotingEditor = () => {
        gotoInstanceScreen({structureKey, instanceKey, screenKey: 'admin', params: {}})
    }

    const openConversation = () => {
        gotoInstance({structureKey: "question", instanceKey: questionkey})
    }


    return <TeaserScreen color={colorPinkBackground}>
            <View style={{gap:8}}>
                <Heading label={videoVotingEnabled ? "Video voting is enabled for this video. To participate, please start the video." : "Video voting is not enabled for this video."}></Heading>
                {isAdmin ? <CTAButton type='secondary' label={"Manage Video Voting (Admin)"} onPress={openVideoVotingEditor}></CTAButton> : <></>}
                {questionkey ? <CTAButton label={"Join the Conversation"} onPress={openConversation}></CTAButton> : <></>}  
            </View>
    </TeaserScreen>
    
}

const defaultQuestionTextDE = "Frauengesundheit: kaum beachtet, wenig erforscht - was denkst du darüber?"
const defaultQuestionTextConnectedDE = "Offene Ehe, Polyamorie - was denkst du darüber?"
const defaultQuestionTextEN = "Women's health: rarely considered, little researched - what do you think?"
const defaultQuestionTextConnectedEN = "Open marriage, polyamory - What do you think?"

function DefaultTemplateCreation() {
    const datastore = useDatastore();
    const questions = useGlobalQuestions()

    const defaultQuestionIndividualDE = questions.find(question=>(question.question === defaultQuestionTextDE));
    const defaultQuestionConnectedDE= questions.find(question=>(question.question === defaultQuestionTextConnectedDE));
    const defaultQuestionIndividualEN = questions.find(question=>(question.question === defaultQuestionTextEN));
    const defaultQuestionConnectedEN= questions.find(question=>(question.question === defaultQuestionTextConnectedEN));
    const videoVotingEnabled = useGlobalProperty("videoVotingEnabled");
    const isAdmin = useIsAdmin();

    //TODO: This is ugly. Improve voting creation once editor is implemented
    const createDefaultQuestion = async () => {
        if (!defaultQuestionIndividualDE) {
            await datastore.callServerAsync('questions', 'add', {question: defaultQuestionTextDE});
        }


        if (!defaultQuestionConnectedDE) {
            await datastore.callServerAsync('questions', 'add', {question: defaultQuestionTextConnectedDE});
        }


        if (!defaultQuestionIndividualEN) {
            await datastore.callServerAsync('questions', 'add', {question: defaultQuestionTextEN});
        }


        if (!defaultQuestionConnectedEN) {
            await datastore.callServerAsync('questions', 'add', {question: defaultQuestionTextConnectedEN});
        }

    }

    const createNewVoting = async (type, language) => {

        let template
        let questionKey
        if(type === "connected") {
            template = language === "de" ? howWeWantToLoveVideoVotingTemplateDE : howWeWantToLoveVideoVotingTemplateEN;
            questionKey = language === "de" ? defaultQuestionConnectedDE.key : defaultQuestionConnectedEN.key;
        } else {
            // Default: individual template
            template = language === "de" ? endometriosisVideoVotingTemplateDE : endometriosisVideoVotingTemplateEN;
            questionKey = language === "de" ?  defaultQuestionIndividualDE.key : defaultQuestionIndividualEN.key;
        }
        
        await datastore.callServerAsync('videovoting', 'addVideoVotingTemplate', {
            template, questionThreadKey: questionKey
        });

        // Linking the article to the default question
        await datastore.callServerAsync('questions', 'select', {questionKey});
    }

    const deleteVideoVoting = async () => {
        console.log("Delete")
        await datastore.callServerAsync('videovoting', 'deleteVideoVoting');
    }

    if(videoVotingEnabled) {
        return <Banner color={colorPinkBackground}>
            <View style={{gap: 8}}>
                <Heading label={!videoVotingEnabled ?  "Video voting is not enabled for this article." : "Video voting is enabled for this video."}></Heading>
                {isAdmin && <CTAButton label="Delete Video Voting" onPress={deleteVideoVoting}></CTAButton>}   
            </View>   
        </Banner>
    }

    return <Banner color={colorPinkBackground}>
        <Heading label={"Create a Video Voting for this Article"}></Heading>
        <Pad size={10} />
        <UtilityText label="This article does not have a video voting yet."/>
        {(defaultQuestionIndividualDE && defaultQuestionConnectedDE) ? <>
            <Pad />
            <Separator />
            <Pad />
            <Heading label="Individual Questions" />
            <Pad size={10} />
            <UtilityText type="large" label="Endometriosis (German)"/>
            <CTAButton label={"Create Video Voting"} onPress={() => createNewVoting("individual", "de")}></CTAButton>
            <Pad size={10} />
            <UtilityText type="large" label="Endometriosis (English)"/>
            <CTAButton label={"Create Video Voting"} onPress={()=>createNewVoting("individual", "en")}></CTAButton>
            <Pad />
            {/* <Separator />
            <Pad /> */}
            <Heading label="Connected Questions" />
            <Pad size={10} />
            <UtilityText type="large" label="Open relationships (German)"/>
            <CTAButton label={"Create Video Voting"} onPress={()=>createNewVoting("connected", "de")}></CTAButton>
            <Pad size={10} />
            <UtilityText type="large" label="Open relationships (English)"/>
            <CTAButton label={"Create Video Voting"} onPress={()=>createNewVoting("connected", "en")}></CTAButton>
        </> :
        <>
            <Pad />
            <Separator />
            <Pad />
            <UtilityText label={"Please create default question threads first."}></UtilityText>
            <Pad size={10} />
            <CTAButton label={"Create Default Questions"} onPress={createDefaultQuestion}></CTAButton>
        </>}
    </Banner>
}