import { ChevronDown, ChevronUp } from "@carbon/icons-react";
import { HorizBox, HoverView } from "np-platform-client/component/basics";
import { useDatastore } from "np-platform-client/util/datastore";
import { logEventAsync } from "np-platform-client/util/eventlog";
import React from "react";
import { StyleSheet, View } from "react-native";

/* Differences compared to the regular AccordionField:
- Chevrons always have the same size (16 x 16). Before this adjustment, the chevrons caused a problem in the "Votings" tab of the Video Voting Tool. One of the listed questions had a smaller chevron.
- Background color on hover and on press is black + transparency. This is how the design does it. Using an opaque gray color like the regular AccordionField doesn't work as well when a component's background color isn't white, e.g. the blue Conversation Helper widget.
- titleContent is wrapped into a View with a set width. Otherwise long text can cause issues and doesn't break into multiple lines.
*/
export function REPLACE_ZDF_AccordionField({ titleContent, defaultOpen, forceOpen, children, nameInLog = "" }) {
    const datastore = useDatastore();
    const s = AccordionFieldStyle;
    const [open, setOpen] = React.useState(defaultOpen);

    const pressAccordionField = () => {
        open
            ? logEventAsync(datastore, "accordionfield-close", { name: nameInLog })
            : logEventAsync(datastore, "accordionfield-open", { name: nameInLog });
        setOpen(!open);
    }

    return (
        <View>
            <HoverView style={s.titleBar} hoverStyle={s.hover} pressedStyle={s.pressed} onPress={pressAccordionField}>
                <HorizBox center spread>
                    <View style={s.titleContent}>{titleContent}</View>
                    {!forceOpen && <View style={s.chevron}>{open ? <ChevronUp /> : <ChevronDown />}</View>}
                </HorizBox>
            </HoverView>
            {(open || forceOpen) && children}
        </View>
    );
}

const AccordionFieldStyle = StyleSheet.create({
    titleBar: {
        minHeight: 48,
        justifyContent: "center",
        paddingRight: 16,
    },
    titleContent: {
        width: "calc(100% - 16px)",
    },
    hover: {
        backgroundColor: "rgba(0, 0, 0, 0.1)",
    },
    pressed: {
        backgroundColor: "rgba(0, 0, 0, 0.2)",
    },
    chevron: {
        height: 16,
        width: 16
    },
});