import { Narrow } from "np-platform-client/component/basics"
import { Heading, UtilityText } from "np-platform-client/component/text"
import { View } from "react-native"
import { colorGreyPopupBackground, colorGreen } from "np-platform-client/component/color"
import { DemoHeader } from "np-platform-client/component/demo"
import { PercentageBar, PercentageBarContainer, PercentageSegment } from "../videovoting/videovotingPercentageBars"


export function PercentageBarDemo() {

    // Data viz colors that are not yet in np-platform's color.js
    const colorYellow = "#FFC841";
    const colorLightRed = "#FA7355";

    return <Narrow>
        <View style={{ gap: 24 }}>
            <DemoHeader label='Percentage Bars Demo'/>

            {/* TODO: Can we format component and variable names like code snippets? */}

            <UtilityText label={"Percentage bars consist of PercentageSegments in a PercentageBarContainer."}></UtilityText>
            <UtilityText label={"For ease of use, a PercentageBar will create the necessary PercentageSegments dynamically based on the percentages and colors supplied."}></UtilityText>
            
            <View>
                <Heading text="A single PercentageSegment always has full width"/>
                <UtilityText text="Example: percentage={50}"/>
                <PercentageBarContainer backgroundColor={colorGreyPopupBackground} thickness={20}>
                    <PercentageSegment color={colorGreen} percentage={50}/>
                </PercentageBarContainer>
            </View>
            <View>
                <Heading text= "2 PercentageSegments"/>
                <PercentageBarContainer backgroundColor={colorGreyPopupBackground} thickness={20}>
                    <PercentageSegment color={colorGreen} percentage={50}></PercentageSegment>
                    <PercentageSegment percentage={50}></PercentageSegment>
                </PercentageBarContainer>
            </View>
            <View>
                <Heading text= "Multiple PercentageSegments"/>
                <PercentageBarContainer backgroundColor={colorGreyPopupBackground} thickness={20}>
                    <PercentageSegment percentage={25} color={colorGreen}></PercentageSegment>
                    <PercentageSegment percentage={50} color={colorYellow}></PercentageSegment>
                    <PercentageSegment percentage={15} color={colorLightRed}></PercentageSegment>
                    <PercentageSegment percentage={10}></PercentageSegment>
                </PercentageBarContainer>
            </View>

            <View>
                <Heading text= "PercentageBar"/>
                <PercentageBarContainer backgroundColor={colorGreyPopupBackground} thickness={20}>
                    <PercentageBar percentages={[25,50,15]} colors={[colorGreen, colorYellow, colorLightRed]}></PercentageBar>
                </PercentageBarContainer>
            </View>

            <View>
                <Heading text="Use totalValue to work with non-percentage values"/>
                <UtilityText text="Example: totalValue={5}"/>
                <PercentageBarContainer backgroundColor={colorGreyPopupBackground} thickness={20}>
                    <PercentageBar percentages={[1,1,2]} colors={[colorGreen, colorYellow, colorLightRed]} totalValue={5}>
                        {[1,1,2].map((x,i)=>(<UtilityText key={i} text={x}></UtilityText>))}
                    </PercentageBar>
                </PercentageBarContainer>
            </View>

            <View>
                <Heading text= "Styling using an outer container"/>
                <View style={{borderRadius:10, height: 20, borderWidth:2, overflow:"hidden"}}>
                    <PercentageBarContainer backgroundColor={colorGreyPopupBackground}>
                        <PercentageBar percentages={[50]} colors={[colorGreen]}></PercentageBar>
                    </PercentageBarContainer>
                </View>       
            </View>

            <View>
                <Heading text= "Vertical bars"/>
               <View style={{height: 200, gap:24,  flexDirection: "row"}}>
                    <PercentageBarContainer vertical backgroundColor={colorGreyPopupBackground} thickness={20}>
                        <PercentageBar percentages={[50]} colors={[colorGreen]}></PercentageBar>
                    </PercentageBarContainer>
                    <PercentageBarContainer vertical backgroundColor={colorGreyPopupBackground} thickness={20}>
                        <PercentageBar percentages={[25,50,15]} colors={[colorGreen, colorYellow, colorLightRed]}></PercentageBar>
                    </PercentageBarContainer>
                </View> 
            </View>     
        </View>
    </Narrow>
}