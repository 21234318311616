import { LengthLimitFeature } from "np-platform-client/feature/LengthLimitFeature"
import { UpvoteFeature } from "np-platform-client/feature/UpvoteFeature"
import { VideoSyncFeature } from "./deprecated/VideoSyncFeature"
import { AgreeDisagreeFeature, CommentSliderFeature, CommentSliderDisableAnonymousFeature, CommentSliderDisableVisualizationFeature, CommentSliderHorizVisualizationFeature, CommentSliderPollPickerFeature, CommentSliderDefaultFeature, YesNoFeature, CommentSliderVertVisualizationFeature, CommentSliderSliderFeature, CommentSliderEnableVisualizationFeature } from "./question/CommentSliderFeature"
import { PreModeratorFeature } from "./question/PreModeratorFeature"
import { ProfileArticleRecommendationsFeature } from "./zdf/ProfileArticleRecommendationsFeature"
import { ProfileConversationRecommendationsFeature } from "./zdf/ProfileConversationRecommendationsFeature"
import { ProfileInterestsFeature } from "./zdf/ProfileInterestsFeature"
import { VideoQuoteFeature } from "./zdf/VideoQuoteFeature"
import { ArticleTopicTaggingFeature } from "./zdf/ArticleTopicTagFeature"
import { ConvoMatchFeature } from "./zdf/ConvoMatchFeature"
import { ProfileAnswersFeature } from "./profile/ProfileAswersFeature"
import { TopicFeature } from './cbcrc/admin/TopicCreationFeature'
import { CommentPreviewTeaserFeature, EditorialQuestionFeature, StandardTeaserFeature, ZeroStateGraphicTeaserFeature } from "./connect/EditorialQuestionFeature"
import { NarwwhalReactionsFeature, NewPublicReactionsFeature, ReactionTypesFeature } from "./question/ReactionTypesFeature"
import { ReplyNotificationsFeature } from "np-platform-client/feature/ReplyNotifsFeature"
import { VideoVotingFeature, VideoVotingVideoOverlayFeature } from "./zdf/videovoting/VideoVotingArticleFeature"
import { VideoVotingOverviewFeature } from "./zdf/videovoting/VideoVotingOverviewFeature"
import { ProfileSettingsFeature } from "./zdf/ProfileSettingsFeature"
import { ProfileVideoVotingSettingFeature } from "./zdf/videovoting/ProfileVideoVotingSettingFeature.js"
import { PollFeature } from "./cbcrc/poll/poll"
import { AdminRemoveFeature } from "./question/AdminRemoveFeature.js"
import { CBCRCDemoFeature } from "../demo/cbcrc/componentdemo.js"
import { ZDFDemoFeature } from "../contrib/zdf/componentdemozdf.js"
import { DemoConversationFeatures } from "../demo/conversationfeatures.js"
import { RepresentingPerspectivesFeature } from "./question/RepresentingPerspectives.js"
import { ProfileTitledWriterFeature } from "./cbcrc/profile/profile"
import { TranslationFeature } from "../contrib/srg/translation/TranslationFeature"
import { PrivateCommentFeedbackFeature } from "./zdf/PrivateCommentFeedbackFeature.js"
import { ConversationHelperAddonFeatures, ConversationHelperFeature } from "./zdf/ConversationHelperFeature.js"
import { ConversationHelperSettingsFeatures } from "./zdf/ConversationHelperAdminFeature.js"
import { CHOOSEONE, COMPOSITE, SECTION, SUBSECTION } from "np-platform-client/feature"
import { TeaserDemoFeature } from "../demo/teaserdemo.js"
import { ProfileAnswersDemo } from "../demo/profileanswers-demo"
import { TopicTeaserFeature } from "./cbcrc/connect/TopicTeaserFeature"
import { PinCommentFeature } from "./question/PinCommentFeature"

export const newFeatures = {
    article: [
        COMPOSITE(EditorialQuestionFeature, [
            CHOOSEONE('Teaser Type', [
                StandardTeaserFeature,
                CommentPreviewTeaserFeature,
                ZeroStateGraphicTeaserFeature,
                TopicTeaserFeature,
            ])
        ]),
        // EditorialQuestionFeature,
        VideoVotingFeature,
        VideoVotingVideoOverlayFeature
    ],
    topic: [
        COMPOSITE(TopicFeature, [
            CHOOSEONE('Teaser Type', [
                StandardTeaserFeature,
            ])
        ]),
        COMPOSITE(PreModeratorFeature, [
            AdminRemoveFeature
        ]),
        PollFeature,
        UpvoteFeature,
    ],
    simplecomments: [
        CommentSliderFeature,
        TranslationFeature,
        PreModeratorFeature,
        VideoSyncFeature,
        VideoQuoteFeature,
        ArticleTopicTaggingFeature,
        ConvoMatchFeature,
        AdminRemoveFeature
    ],
    question: [
        SECTION('Conversation Type', [
            COMPOSITE(CommentSliderFeature, [
                CHOOSEONE('Spectrum', [
                    YesNoFeature,
                    AgreeDisagreeFeature
                ]),
                CHOOSEONE('Composer', [
                    CommentSliderPollPickerFeature,
                    CommentSliderSliderFeature
                ]),
                SUBSECTION('Data Viz', [
                    COMPOSITE(CommentSliderEnableVisualizationFeature, [
                        CHOOSEONE(null, [
                            CommentSliderHorizVisualizationFeature,
                            CommentSliderVertVisualizationFeature
                        ]),
                    ]),
                ]),
            ]),
            RepresentingPerspectivesFeature,
            COMPOSITE(ReactionTypesFeature, [
                CHOOSEONE('Reaction Set', [
                    NewPublicReactionsFeature,
                    NarwwhalReactionsFeature
                ])
            ]),
        ]),
        SECTION('General', [
            LengthLimitFeature,
            COMPOSITE(PreModeratorFeature, [
                AdminRemoveFeature
            ]),
            COMPOSITE(ConversationHelperFeature, [
                ...ConversationHelperAddonFeatures,
                ...ConversationHelperSettingsFeatures,
            ]),
            VideoVotingOverviewFeature,
            ReplyNotificationsFeature,
            TranslationFeature,
            UpvoteFeature,
            PinCommentFeature,
        ])
    ],
    profile: [
        SECTION('Activity', [
            ProfileAnswersFeature,
        ]),
        SECTION('ZDF', [
            ProfileInterestsFeature,
            ProfileConversationRecommendationsFeature,
            ProfileArticleRecommendationsFeature,
            COMPOSITE(ProfileSettingsFeature, [
                ProfileVideoVotingSettingFeature
            ])
        ]),
        SECTION('CBCRC', [
            ProfileTitledWriterFeature,
        ])
    ],
    componentdemo: [
        SECTION('PSI Components', [
            CBCRCDemoFeature,
            ZDFDemoFeature,
            DemoConversationFeatures,
            TeaserDemoFeature,
            ProfileAnswersDemo
        ])
    ]
}

export const newDefaultFeatures = {
    componentdemo: {
        demo_cbcrc: true,
        demo_zdf: true,
        demo_conversation: true,
        demo_teaser: true
    },
    article: {
        editorialquestion: true,
        zerostateteaser: true,
        videovotingArticle: false,
        videovotingArticleTextIntegration: false,
    },
    topic: {
        editorialtopic: true,
        poll: true,
        upvote: true,
        premoderator: false,
    },
    articlequestions: {
        upvotequestion: true,
        questionteaser: true
    },
    simplecomments: {
        premoderator: false,
        articletopictagging: false,
        convomatch: false,
        translationfeature: false
    },
    question: {
        lengthlimit: false,
        upvote: true,
        commentslider: false,
        translationfeature: false,
        premoderator: false,
        votingoverview: false,

        // Radio button first features: TODO - auto-select these
        commentslider_yes: true,
        commentslider_poll_picker: true,
        commentslider_horiz_visualization: true,
        commentslider_enable_visualization: true,
        newpublicreactions: true,

        //Conversation helper Settings
        conversationhelper_timing_comments: true,
        conversationhelper_timing_comments_1: true,
        conversationhelper_personality_a: true,
        conversationhelper_scope_all_comments: true,
        conversationhelper_scope_threshold_1: true,
        conversationhelper_top_x_percent_3: true,
        conversationhelper_guidance_update_comment_interval_0: true,
        conversationhelper_guidance_update_time_interval_0: true
    },
    profile: {
        profileanswers: true,
        // profileinterests: true,
        // profileconversationrecommendations: true,
        // profilearticlerecommendations: true

        // These features are turned on by default so they are visible on everyone's profile. Admins cannot turn profile features on globally, only for specific profile instances.
        // profilesettings: true,
        // profilevideovotingsetting: true
    }
}