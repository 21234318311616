import { colorDisabledText, colorGreyBorder, colorGreyHoverBorder, colorWhite } from "np-platform-client/component/color";
import { AutoSizeTextInput } from "np-platform-client/component/text";
import { useTranslation } from "np-platform-client/component/translation";
import { useState } from "react";
import { StyleSheet, TextInput } from "react-native";

export function ValidatedTextField({value, big=false, autoFocus=false, placeholder, placeholderParams, onChange, valid}) {
    const s = ValidatedTextFieldStyle;
    const tPlaceholder = useTranslation(placeholder, placeholderParams);
    const [hover, setHover] = useState(false);
    return <TextInput
        value={value ?? ''}
        onChangeText={onChange} 
        emptyHeight={big ? 300 : 50}
        autoFocus={autoFocus}
        style={[s.textField, hover ? s.hover : null, !valid ? s.invalidInput : null]} 
        // style={s.textField}
        // hoverStyle={s.hover}
        placeholder={tPlaceholder}
        placeholderTextColor={colorDisabledText}
        onMouseEnter={() => setHover(true)} onMouseLeave={() => setHover(false)}/>
}

const ValidatedTextFieldStyle = StyleSheet.create({
    textField: {
        borderColor: colorGreyBorder,
        backgroundColor: colorWhite,
        borderWidth: 1,
        borderRadius: 4,
        paddingHorizontal: 16,
        paddingVertical: 15,
        fontSize: 16,
        fontFamily: 'IBMPlexMono_400Regular',
    },
    hover: {
        borderColor: colorGreyHoverBorder
    },
    invalidInput: {
        backgroundColor: "#fcebeb",
    }
})