import { ScrollView, View } from "react-native";
import { IconButton, Tag } from "np-platform-client/component/button";
import { HorizBox, LoadingScreen, Pad, PadBox, Separator } from "np-platform-client/component/basics";
import { Heading, UtilityText } from "np-platform-client/component/text";
import { useCollection, useDatastore, usePersonaKey } from "np-platform-client/util/datastore";
import { useState } from "react";
import { Banner } from "np-platform-client/component/banner";
import { Comment } from "np-platform-client/component/comment";
import { ArticleCard } from "../../structure/question";

const baseTopics = [
    "Technology",
    "Travel",
    "Food",
    "Cooking",
    "Health",
    "Fitness",
    "Fashion",
    "Style",
    "Arts",
    "Culture",
    "Music",
    "Movies",
    "Literature",
    "Books",
    "Sports",
    "Photography",
    "DIY",
    "Crafts",
    "Science",
    "History",
    "Nature",
    "Environment",
    "Gaming",
    "Parenting",
    "Family",
    "Personal Finance",
    "Education",
    "Career",
    "Professional Development",
    "Home Decor",
    "Interior Design",
    "Relationships",
    "Dating",
    "Psychology",
    "Mental Health",
    "Spirituality",
    "Meditation",
    "Cars",
    "Automotive",
    "Outdoor Activities",
    "Adventure",
    "Pets",
    "Animals",
    "Sustainability",
    "Green Living",
    "Social Media",
    "Influencers",
    "News",
    "Current Events",
    "LGBTQ+"
];

export const ProfileInterestsFeature = {
    name: 'Profile Interests',
    key: 'profileinterests',
    config: {
        profileWidgets: [ProfileInterestsWidget]
    }
}

function ProfileInterestsWidget() {
    return <InterestsBox></InterestsBox>
}

export function InterestsBox(){
    const personaKey = usePersonaKey();
    const [loading, setLoading] = useState(false);
    const datastore = useDatastore()

    let interests = datastore.getGlobalProperty("interests")
    
    async function RemoveInterest({interest}){
        setLoading(true)
        await datastore.callServerAsync('interests', 'removeInterest', {personaKey, interest});
        setLoading(false)
    }

    let myInterestContent = null;
    if (interests) {
        interests = interests.filter(i => (i !== "none"))
        myInterestContent = (
            <ScrollView horizontal style={{padding: 4}}>
                    {interests.map(i => (<IconButton key={i} label={i + " 🗑️"} onPress={() => RemoveInterest({interest: i})}></IconButton>))}
                
            </ScrollView>
        );
    } else {
        interests = []
        myInterestContent = (
            <UtilityText strong label={"Your are currently following no topic!"}></UtilityText>
        );
    }
    
    const addInterest = async (input) => {
        setLoading(true)
        await datastore.callServerAsync('interests', 'addInterest', {personaKey, interest: input});
        setLoading(false)
    }

    const allInterests = baseTopics.filter(topic => !interests.includes(topic));
    const allInterestsContent = (
        <ScrollView horizontal style={{padding: 4}}>
            {allInterests.map(i => (<IconButton key={i} label={"➕ " + i} onPress={() => addInterest(i)}></IconButton>))}
        </ScrollView>
    );
    
    return <View>
        <Separator></Separator>
        <Pad></Pad>
        <HorizBox>
            <Heading level={1} label={"My Interests"}></Heading>
            {loading ? <LoadingScreen label="Updating interests..."></LoadingScreen> : <></>}
        </HorizBox>
      
        <Pad vert={4}></Pad>
        <HorizBox>
        <UtilityText label={"All Topics:"}></UtilityText>
            <Pad></Pad>
            {allInterestsContent}
        </HorizBox>
        <Pad></Pad>
        <HorizBox center={true}>
            <UtilityText label={"Following:"}></UtilityText>
            <Pad></Pad>
            {myInterestContent}
        </HorizBox>
        <InterestProposalBox></InterestProposalBox>
    </View>
    
}

function InterestProposalBox(){
    const personaKey = usePersonaKey();
    let myProposals = useCollection("interestproposal")
    const datastore = useDatastore();

    console.log(myProposals)

    let interests = datastore.getGlobalProperty("interests")
    if(!interests) interests = []

    console.log(myProposals)
    console.log(interests)

    if(!myProposals) return <></>
    
    // Show only new proposals
    myProposals = myProposals.filter(proposal => (!interests.includes(proposal.tag)))
    if(myProposals.length === 0) return <></>

    const AcceptProposal = async ({proposal, accept}) => {
        await datastore.deleteObject("interestproposal", proposal.commentKey)
        if(accept){
            await datastore.callServerAsync('interests', 'addInterest', {personaKey, interest: proposal.tag});
        }
    }

    return <View>
            <Separator></Separator>
            <Pad></Pad>
            {myProposals.map(proposal =>(
                <PadBox key={proposal.commentKey} horiz={16} vert={16}>
                  <Banner color="lightgray">
                    <Tag type='emphasized'  label='Do you have a new interest?' color={"white"}/>
                    <Pad></Pad>
                    <ArticleCard article={proposal.article} thumbsize={32}></ArticleCard>
                    <Pad vert={4}></Pad>
                    <Comment commentKey={proposal.commentKey}></Comment>
                    <Pad></Pad>
                    <HorizBox center>
                        <UtilityText strong={true} label='Following topic might interest you:'/>
                        <Pad size={4}></Pad>
                        <Tag color={"white"}  label={proposal.tag}></Tag>
                    </HorizBox>
                    <Pad></Pad>
                    <HorizBox spread>
                        <IconButton label={"Add Interest"} onPress={()=>{AcceptProposal({proposal, accept: true})}}></IconButton>
                        <Pad size={4}></Pad>
                        <IconButton label={"Dismiss"} onPress={()=>{AcceptProposal({proposal, accept: false})}}></IconButton>
                    </HorizBox>
                </Banner>  
                </PadBox>
                
            ))}
    </View>
}