import { DemoSection } from "np-platform-client/component/demo"
import { View } from "react-native-web"
import { Datastore } from "np-platform-client/util/datastore"
import { TopicTeaserFeature, AdaptiveLayoutTopicTeaserWithComment, WideTopicTeaserWithComment, NarrowTopicTeaserWithComment, TopicInfo } from "../../feature/cbcrc/connect/TopicTeaserFeature"

export function TopicTeaserScreen() {
    const topic = {
        key: 1,
        from: 'a',
        text: 'Is Godzilla good?',
    }

    const commentPreview = {
        key: 1,
        from: 'a',
        text: 'I love Godzilla. He is so cute.',
        time: Date.now(),
        upvoteCount: 5
    }

    const collections = {
        derived_topicPreview: [
            { key: 1, commentCount: 1234, commentPreview }
        ]
    }


    const emptyStateCollection = {
        derived_topicPreview: [
            { key: 1 }
        ]
    }

    const configWithGraphic = { ...TopicTeaserFeature.defaultConfig, showZeroStateGraphic: true };

    // return null;
    return <View>
        <Datastore collections={collections}>
            <DemoSection label='Adaptive Layout Question Teaser With Comment'>
                <AdaptiveLayoutTopicTeaserWithComment topic={topic} commentPreview={commentPreview} commentCount={12} />
            </DemoSection>
            <DemoSection label='Wide Question Teaser With Comment'>
                <WideTopicTeaserWithComment topic={topic} commentPreview={commentPreview} commentCount={12} />
            </DemoSection>
            <DemoSection label='Narrow Question Teaser With Comment'>
                <NarrowTopicTeaserWithComment topic={topic} commentPreview={commentPreview} commentCount={12} />
            </DemoSection>
        </Datastore>
        <Datastore collections={emptyStateCollection} config={configWithGraphic}>
            <DemoSection label='Adaptive Empty State With Graphic'>
                <AdaptiveLayoutTopicTeaserWithComment topic={topic} />
            </DemoSection>
        </Datastore>

        <DemoSection label='Topic Info'>
            <TopicInfo title={topic.text} structureKey={"topic"} commentCount={42} />
        </DemoSection>

    </View>
}
