import { Narrow } from "np-platform-client/component/basics"
import { View } from "react-native"
import { DemoSection } from "np-platform-client/component/demo"
import { StructureDemo } from "np-platform-client/util/instance"
import { ConversationHelperAdminScreen, ThoughtProvokingPrompt, ThoughtProvokingPromptHistory, ThoughtProvokingPromptHistoryItem, ThoughtProvokingPromptSelector } from "../../../../feature/zdf/ConversationHelperFeature";
import { Datastore } from "np-platform-client/util/datastore";
import { conversationHelperDemoData }  from "./conversationhelperdemo.data";
import { AutoModeratorReactionTypesWidget } from "../../../../component/zdf/conversationhelper/autoModeratorReactions";

export function ThoughtProvokingPromptDemo() {

    const collections = {
        thought_provoking_prompt: conversationHelperDemoData.thoughtProvokingPrompts,
        thought_provoking_prompt_reactions: conversationHelperDemoData.reactions,
        summary: conversationHelperDemoData.summaries
    };
    const config={
        reactionTypes: conversationHelperDemoData.reactionTypes,
    }

    return (
        <Narrow>
            <View style={{ gap: 12 }}>

                <DemoSection label="Thought-Provoking Prompt">
                    <Datastore collections={collections} config>
                        <ThoughtProvokingPrompt currentThoughtProvokingPrompt={conversationHelperDemoData.thoughtProvokingPrompts[2]} />
                    </Datastore>
                </DemoSection>

                <DemoSection label="Thought-Provoking Prompt with Reactions">
                    <Datastore collections={collections} config={{...config, reactionsEnabled: true }}>
                        <ThoughtProvokingPrompt currentThoughtProvokingPrompt={conversationHelperDemoData.thoughtProvokingPrompts[2]} />
                    </Datastore>
                </DemoSection>

                <DemoSection label="Thought-Provoking Prompt with History">
                    <Datastore collections={collections} config={{...config, historyEnabled: true }}>
                        <ThoughtProvokingPrompt currentThoughtProvokingPrompt={conversationHelperDemoData.thoughtProvokingPrompts[2]} />
                    </Datastore>
                </DemoSection>

                <DemoSection label="Thought-Provoking Prompt with Summary">
                    <Datastore collections={collections} config={{...config, summaryEnabled: true }}>
                        <ThoughtProvokingPrompt currentThoughtProvokingPrompt={conversationHelperDemoData.thoughtProvokingPrompts[2]} />
                    </Datastore>
                </DemoSection>

                <Datastore collections={collections} config={{...config, summaryEnabled: true }}>
                    <DemoSection label="Thought-Provoking Prompt History">
                        <ThoughtProvokingPromptHistory/>
                    </DemoSection>
                    <DemoSection label="Thought-Provoking Prompt History Item">
                        <ThoughtProvokingPromptHistoryItem thoughtProvokingPrompt={conversationHelperDemoData.thoughtProvokingPrompts[0]} />
                    </DemoSection>
                </Datastore>

                <Datastore collections={collections} config={{...config, summaryEnabled: true, reactionsEnabled: true }}>
                    <DemoSection label="Thought-Provoking Prompt History Item with Reactions">
                        <ThoughtProvokingPromptHistoryItem thoughtProvokingPrompt={conversationHelperDemoData.thoughtProvokingPrompts[0]} />
                    </DemoSection>
                </Datastore>
                
                <Datastore collections={collections} config={{...config, summaryEnabled: true }}>
                    <DemoSection label="Thought-Provoking Prompt Reaction Widget - Newest Prompt">
                        <AutoModeratorReactionTypesWidget thoughtProvokingPrompt={conversationHelperDemoData.thoughtProvokingPrompts[2]} />
                    </DemoSection>
                    <DemoSection label="Thought-Provoking Prompt Reaction Widget - Previous Prompt">
                        <AutoModeratorReactionTypesWidget thoughtProvokingPrompt={conversationHelperDemoData.thoughtProvokingPrompts[0]} />
                    </DemoSection>
                </Datastore>
            </View>
        </Narrow>
    );
}

export function ThoughtProvokingPromptAdminDemo () {
    return <View>
        <Datastore
            globals = {conversationHelperDemoData.globals}
            isAdmin
            collections={{
                thought_provoking_prompt: conversationHelperDemoData.thoughtProvokingPrompts,
                thought_provoking_prompt_reactions: conversationHelperDemoData.reactions,
                comment: conversationHelperDemoData.comments
            }}
            config={{
                reactionTypes: conversationHelperDemoData.reactionTypes,
            }}
            serverCall={conversationHelperDemoData.serverCalls}
        >
            <ConversationHelperAdminScreen></ConversationHelperAdminScreen>

        </Datastore>
    </View>
}


export function ConversationHelperFullDemo() {
    const collections = { comment: conversationHelperDemoData.comments, thought_provoking_prompt: conversationHelperDemoData.thoughtProvokingPrompts }
    return <StructureDemo serverCall={conversationHelperDemoData.serverCalls} globals={conversationHelperDemoData.globals} collections={collections} structureKey='question' features={{ conversationhelper: true }} />
}

export function ConversationHelperAdminFullDemo() {
    const collections = { comment: conversationHelperDemoData.comments, thought_provoking_prompt: conversationHelperDemoData.thoughtProvokingPrompts }
    return <StructureDemo screenKey={"convohelperadmin"} globals={conversationHelperDemoData.globals} collections={collections} structureKey='question' features={{ conversationhelper: true }} />
}