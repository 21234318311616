import { howWeWantToLoveVideoVotingTemplateEN } from "../../../feature/zdf/videovoting/examples/votingtemplate"
import { Narrow } from "np-platform-client/component/basics"
import { DemoSection } from "np-platform-client/component/demo"
import { VideoVotingQuestionDialog } from "../structure/videovoting";

export function VideoVotingQuestionDialogDemo() {
    const question = howWeWantToLoveVideoVotingTemplateEN.questions[0];

    // TODO: How can we make the voting options interactable? Clicking an option results in an error.
    return (
        <Narrow>
            <DemoSection label="Question Dialog">
                <VideoVotingQuestionDialog
                    question={question}
                    onFinishQuestionVoting={null}
                />
            </DemoSection>
            <DemoSection label="Question Dialog with Secondary Header">
                <VideoVotingQuestionDialog
                    question={question}
                    onFinishQuestionVoting={null}
                    headerText={"What do you think about it now?"}
                />
            </DemoSection>
        </Narrow>
    );
}