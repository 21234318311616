import { useEffect } from "react";
import { useState } from "react";

var global_videoTime = null;
var global_videoUrl = null;
var global_videoDuration = null;
var global_pauseState = null;

export function useVideoTime() {
    const [time, setTime] = useState(0);
    useEffect(() => {
        if (!global_videoUrl) {
            window.parent.postMessage({type: 'psi-get-video'}, '*');
        }
    }, [global_videoUrl]);
    useEffect(() => {
        const callback = window.addEventListener('message', (event) => {
            if (event.data.type === 'psi-video-timeupdate') {
                setTime(event.data.time.toFixed(1));
                global_videoUrl = event.data.url;
            }
        });
        return () => {
            window.removeEventListener('message', callback);
        }
    }, []);
    return time || global_videoTime || 0;
}

export function usePlayedVideo() {
    const [url, setUrl] = useState(null);
    const [time, setTime] = useState(0);
    const [duration, setDuration] = useState(0);
    const [paused, setPaused] = useState(true);
    const [ended, setEnded] = useState(false);
    const [ready, setReady] = useState(false)
    useEffect(() => {
        if (!global_videoUrl || !global_videoDuration) {
            window.parent.postMessage({type: 'psi-get-video'}, '*');
        }
    }, [global_videoUrl, global_videoDuration]);
    useEffect(() => {
        const callback = window.addEventListener('message', (event) => {
            if (event.data.type === 'psi-video-timeupdate') {
                setUrl(event.data.url);
                setTime(event.data.time.toFixed(1));
                setPaused(event.data.paused)
                setReady(event.data.ready)
                global_videoUrl = event.data.url;
                global_videoTime = event.data.time.toFixed(1);
                global_pauseState = event.data.paused

                if (event.data.duration !== null && event.data.duration !== undefined && !isNaN(event.data.duration)) {
                    setDuration(event.data.duration);
                    global_videoDuration = event.data.duration;
                }
            }

            if (event.data.type === 'psi-video-ended') {
                setEnded(true);
            }

            if (event.data.type === 'psi-video-ready') {
                setReady(true);
            }
        });
        return () => {
            window.removeEventListener('message', callback);
        }
    }, []);
    return {
        time: time || global_videoTime,
        url: url || global_videoUrl,
        duration: duration || global_videoDuration,
        paused: paused || global_pauseState,
        ended: ended,
        ready: ready
    }
}

export function getVideoTime() {
    return global_videoTime;
}   

export function getVideoUrl() {
    return global_videoUrl;
}

export function getVideoDuration() {
    return global_videoDuration;
}

export function setVideoTime(videoTime) {
    window.parent.postMessage({ type: 'psi-set-video-time', time: videoTime }, '*');
}

export function pauseVideo() {
    window.parent.postMessage({ type: 'psi-pause-video' }, '*');
}

export function playVideo() {
    window.parent.postMessage({ type: 'psi-play-video' }, '*');
}

export function hideVideo() {
    window.parent.postMessage({ type: 'psi-hide-video' }, '*');
}

export function showVideo() {
    window.parent.postMessage({ type: 'psi-show-video' }, '*');
}

export function hideVideoOverlay() {
    window.parent.postMessage({ type: 'psi-hide-overlay' }, '*');
}

export function showVideoOverlay() {
    window.parent.postMessage({ type: 'psi-show-overlay' }, '*');
}

/** Returns a nicely formatted version of the given video time, e.g. 66.5 turns into 00:01:06 */
export function getPrettyTimestamp(videoTime, omitHours = false) {
    if (videoTime === null || videoTime === undefined) {
        return undefined;
    }

    const hours = Math.trunc(videoTime / 60 / 60).toString().padStart(2, "0");
    const minutes = Math.trunc(videoTime / 60).toString().padStart(2, "0");
    const seconds = Math.trunc(videoTime % 60).toString().padStart(2, "0");
    
    const prettyTimestamp = (omitHours && hours === "00" ? "" : (hours + ":")) + minutes + ":" + seconds;
    return prettyTimestamp;
}

/** Converts a "pretty" timestamp back into seconds, e.g. 00:01:06 turns into 66 */
export function getSecondsFromPrettyTimestamp(timestamp) {
    const timestampFragments = timestamp.split(":");
    let seconds = 0;

    if (timestampFragments.length === 1) {
        seconds = parseInt(timestampFragments[0]);
    } else if (timestampFragments.length === 2) {
        seconds = parseInt(timestampFragments[0]) * 60 + parseInt(timestampFragments[1]);
    } else if (timestampFragments.length === 3) {
        seconds = parseInt(timestampFragments[0]) * 60 * 60 + parseInt(timestampFragments[1]) * 60 + parseInt(timestampFragments[2]);
    }

    return seconds;
}

/** Checks if the given string is formatted like a valid timestamp. Valid formats include `hh:mm:ss`, `mm:ss`, `ss`, and single-digit variations. */
export function validateTimestamp(timestamp) {
    const timestampRegex = /^(?:(?:([01]?\d|2[0-3]):)?([0-5]?\d):)?([0-5]?\d)$/g;

    const valid = (timestamp.match(timestampRegex) !== null);
    return valid;
}
