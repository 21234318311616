import { CommentSliderComponentsDemo, CommentSliderFullDemo } from "./commentsliderdemo"
import { ReactionTypesComponentDemo, ReactionTypesFullDemo } from "./reactiontypesdemo"
import { RepresentingPerspectivesComponentsDemo, RepresentingPerspectivesFullDemo } from "./representingperspectivesdemos"

export const DemoConversationFeatures = {
    key: 'demo_conversation',
    name: 'Demo - Conversation Features',
    config: {
        componentSections: [
            {label: 'Conversation Feature Modules', pages: [
                {
                    label: 'Comment Slider', 
                    key: 'commentslider', 
                    screen: CommentSliderComponentsDemo,
                    designUrl: 'https://www.figma.com/design/SgBNOg9PPIKIsSMAmU32rK/Comments-Slider---Testing?node-id=620-7567&m=dev',
                },
                {
                    label: 'Representing Perspectives', 
                    key: 'representingperspectives', 
                    screen: RepresentingPerspectivesComponentsDemo,
                    designUrl: 'https://www.figma.com/design/3vGCewqJoClk1vyiptYeon/Representing-Perspectives---Testing?node-id=898-1334&m=dev'
                },
                {
                    label: 'Reaction Types', 
                    key: 'reactiontypes', 
                    screen: ReactionTypesComponentDemo,
                    designUrl: 'https://www.figma.com/design/S8vgvD8EmLj9JbvMhP5oD2/Reaction-Types---Testing?node-id=70-1797&m=dev'
                },
            ]},
        ],
        featureSections: [
            {label: 'Question', key: 'conversationfeatures', pages: [
                {label: 'Comment Slider', key: 'commentsliderfull', screen: CommentSliderFullDemo},
                {label: 'Representing Perspectives', key: 'representingperspectivesfull', screen: RepresentingPerspectivesFullDemo},
                {label: 'Reaction Types', key: 'reactiontypesfull', screen: ReactionTypesFullDemo},
            ]},
        ],
    },
}

